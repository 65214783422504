<template>
  <div class="mine">
    <Header />
    <div class="content">
      <div class="panel">
        <MineTab :tabValue="0" />
        <div class="mine-list">
          <div class="user-info">
            <div class="avatar">
              <el-avatar
                size='large'
                shape="square"
                src="https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png"
              ></el-avatar>
  
              <div class="name">{{userData.username}}</div>
            </div>
            <div class="Email">
              <span>Email：</span>
              <p>{{userData.email}}</p>
            </div>
          </div>

          <div class="logout">
            <div class="logout-btn">
              <el-button type="primary" @click="outLogin">Sign Out</el-button></div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <loading :show="loadingShow" />
  </div>
</template>

<script>
import MineTab from "@/components/MineTab/mineTab.vue";
import { userCurrent } from "@/api/index";
export default {
  components: {
    MineTab,
  },
  data() {
    return {
      userData: {},
      loadingShow:false
    }
  },
  mounted() {
    this.getMine();
  },
  methods: {
    outLogin() {
      sessionStorage.removeItem("token");
      let fullPath = this.$route.fullPath;
      console.log(fullPath);
      if (fullPath == "/") {
        this.$router.go(0);
        return;
      }
      this.$router.replace("/");
    },  
    getMine() {
      this.loadingShow = true
      userCurrent({}).then((res) => {
        this.userData = res.data
        this.loadingShow = false
      }).catch(err => {
        this.loadingShow = false;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.mine {
  .content {
    min-height: 70vh;
    background: #f5f7fa;
    padding-bottom: 32px;

    .panel {
      width: 1160px;
      margin: 0 auto;
      padding-top: 24px;
      display: flex;

      .mine-list {
        width: calc(100% - 148px);
        min-height: 0;
        background: #fff;
        padding: 32px 64px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .avatar{
          display: flex;
          margin-bottom: 32px;
          .name{
            font-size: 20px;
            margin-left: 24px;
            margin-top: 32px;
          }
        }

        .Email{
          display: flex;
          span{
            font-size: 16px;
            color: #ccc;
            display: block;
            margin-bottom: 12px;
          }
        }


        ::v-deep{
          .el-avatar--large{
            width: 100px;
            height: 100px;
          }
        }
      }
    }
  }
}
</style>