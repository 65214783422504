<template>
  <div class="hotel-list" @click="closeSearch">
    <div class="content">
      <div class="top">
        <logo :color="'blur'" @click.stop />
        <div class="searchHotel-box">
          <search-hotel ref="searchHotel" />
        </div>
        <avatar :color="'blur'" @click.stop />
      </div>
      <div class="screen">
        <dl>
          <dt>Region</dt>
          <dd class="m-tab">
            <div class="tab-pane">
              <span
                v-for="(item, index) in hotelAreaData"
                :key="index"
                @click="selRegion(item.id)"
                :class="{ 'sel-filter-item': item.id == regionIndex }"
                >{{ item.areaNameDisplay }}</span
              >
            </div>
          </dd>
        </dl>
        <dl class="star-filter">
          <dt>Star</dt>
          <dd class="m-tab">
            <div class="star-item tab-pane">
              <span
                v-for="(item, index) in starData"
                :key="index"
                @click="selStar(item.val)"
                :class="{ 'sel-filter-item': item.val == starIndex }"
                >{{ item.name }}</span
              >
            </div>
          </dd>
        </dl>
        <dl class="advanced-filter">
          <dt>Filter</dt>
          <dd class="m-tab">
            <ul class="tab-bar">
              <li :class="{ 'bar-active': barShow }" @click="selectBar">
                Hotel facilities <i class="el-icon-caret-bottom"></i>
              </li>
            </ul>
            <ul class="tab-pane" v-if="barShow">
              <li>
                <span
                  v-for="(item, index) in facilitiesData"
                  :key="index"
                  @click="selFacilities(item.name)"
                  :class="{ 'sel-filter-item': item.name == facilitiesIndex }"
                  >{{ item.name }}</span
                >
              </li>
            </ul>
          </dd>
        </dl>
      </div>

      <div class="sort">
        <div
          class="filter"
          v-if="regionIndex != '' || starIndex != '' || facilitiesIndex != ''"
        >
          <el-tag
            closable
            @close="tagClose('regionIndex')"
            v-if="regionIndex != ''"
            >{{
              hotelAreaData.find((v) => v.id == regionIndex).areaNameDisplay
            }}</el-tag
          >
          <el-tag
            closable
            @close="tagClose('starIndex')"
            v-if="starIndex != ''"
            >{{ starData.find((v) => v.val == starIndex).name }}</el-tag
          >
          <el-tag
            v-if="facilitiesIndex != ''"
            closable
            @close="tagClose('facilitiesIndex')"
            >{{ facilitiesIndex }}</el-tag
          >
          <el-link type="primary" @click="clearFilter">Clear</el-link>
        </div>

        <div class="sort-box">
          <div @click="sortClick(1)" :class="{ active: sortType == 1 }">
            Default
          </div>
          <div @click="sortClick(2)" :class="{ active: sortType == 2 }">
            Price (Low To High)
          </div>
          <div @click="sortClick(3)" :class="{ active: sortType == 3 }">
            Price (High To Low)
          </div>
          <div @click="sortClick(4)" :class="{ active: sortType == 4 }">
            Star rating (High To Low)
          </div>
        </div>
      </div>

      <div class="list">
        <div class="list-skeleton" v-if="loading">
          <el-skeleton animated>
            <template slot="template">
              <el-skeleton-item
                variant="image"
                style="width: 192px; height: 172px"
              />
              <div
                style="padding: 14px; width: calc(100% - 202px); display: flex"
              >
                <div
                  style="
                    height: 100%;
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <el-skeleton-item variant="p" style="width: 70%" />
                  <el-skeleton-item
                    variant="p"
                    style="width: 100%; margin-bottom: 32px"
                  />
                  <el-skeleton-item variant="p" style="width: 50%" />
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 40%;
                  "
                >
                  <el-skeleton-item
                    variant="text"
                    style="width: 40%; margin-right: 16px"
                  />
                  <el-skeleton-item variant="text" style="width: 30%" />
                </div>
              </div>
            </template>
          </el-skeleton>
          <el-skeleton animated style="margin: 4px 0 4px">
            <template slot="template">
              <el-skeleton-item
                variant="image"
                style="width: 192px; height: 172px"
              />
              <div
                style="padding: 14px; width: calc(100% - 202px); display: flex"
              >
                <div
                  style="
                    height: 100%;
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <el-skeleton-item variant="p" style="width: 70%" />
                  <el-skeleton-item
                    variant="p"
                    style="width: 100%; margin-bottom: 32px"
                  />
                  <el-skeleton-item variant="p" style="width: 50%" />
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 40%;
                  "
                >
                  <el-skeleton-item
                    variant="text"
                    style="width: 40%; margin-right: 16px"
                  />
                  <el-skeleton-item variant="text" style="width: 30%" />
                </div>
              </div>
            </template>
          </el-skeleton>
          <el-skeleton animated>
            <template slot="template">
              <el-skeleton-item
                variant="image"
                style="width: 192px; height: 172px"
              />
              <div
                style="padding: 14px; width: calc(100% - 202px); display: flex"
              >
                <div
                  style="
                    height: 100%;
                    width: 60%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                  "
                >
                  <el-skeleton-item variant="p" style="width: 70%" />
                  <el-skeleton-item
                    variant="p"
                    style="width: 100%; margin-bottom: 32px"
                  />
                  <el-skeleton-item variant="p" style="width: 50%" />
                </div>

                <div
                  style="
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    width: 40%;
                  "
                >
                  <el-skeleton-item
                    variant="text"
                    style="width: 40%; margin-right: 16px"
                  />
                  <el-skeleton-item variant="text" style="width: 30%" />
                </div>
              </div>
            </template>
          </el-skeleton>
        </div>
        <div class="list-box">
          <template v-if="hotelData.length != 0 && loading == false">
            <div
              class="list-item"
              v-for="(item, index) in hotelData"
              :key="index"
              @click="goDateil(item)"
            >
              <div class="topFlag" v-if="item.topFlag">
                <i class="icon iconfont icon-huo"></i>
              </div>
              <div class="list-item-left">
                <img :src="item.faceImageUrl[0]" alt="" />
              </div>
              <div class="list-item-right">
                <div class="info">
                  <h2>
                    {{ item.enName }}
                    <span style="font-size: 12px">({{ item.name }})</span>
                  </h2>
                  <div class="star">
                    <img
                      v-for="item in item.hotelStar"
                      :key="item"
                      src="../../assets/images/hotel_star@2x.png"
                      alt=""
                    />
                  </div>
                  <!-- <el-rate disabled v-model="item.hotelStar"> </el-rate> -->
                  <p class="address">
                    {{ item.placeCountry }} {{ item.placeProvince }}
                    {{ item.placeCity }}
                    <!-- | 距离区域中心步行570米 -->
                    <span class="map-btn" @click.stop="checkMap(item)">
                      Map</span
                    >
                  </p>
                  <div class="tags">
                    <!-- <span>Welcome children to stay</span> -->
                  </div>

                  <div class="room" v-if="item.bestRoomType">
                    <p class="name">{{ item.bestRoomType.nameEn }}</p>
                    <p
                      class="hkBedTypes"
                      v-if="item.bestRoomType.hkBedTypes[0].nameEn"
                    >
                      {{ item.bestRoomType.hkBedTypes[0].nameEn }}
                    </p>
                    <!-- <p class="roomNum">
                      {{ item.bestRoomType.roomNum }} room left at this price on
                      our site
                    </p> -->
                  </div>
                </div>
                <div class="btn">
                  <div class="score-box">
                    <span  v-if="item.score">{{getScoreType(item.score)}}</span>
                    <span  v-if="item.score">{{ item.score }}</span>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: flex-end;
                      align-items: flex-end;
                    "
                  >
                    <div class="prcie" v-if="!item.full">
                      <span
                        class="line-price"
                        v-if="item.bestOriPriceDay != item.minPriceAfterTicket"
                      >
                        {{ item.currency }} {{ item.bestOriPriceDay }}</span
                      >
                      <span class="real-price"
                        ><span>{{ item.currency }}</span>
                        {{ item.minPriceAfterTicket }}</span
                      >
                      <!-- <span class="price-qi">Starting from</span> -->
                    </div>

                    <div>
                      <el-button
                        v-if="item.full"
                        type="info"
                        @click="goDateil(item)"
                        >Sold Out</el-button
                      >
                      <el-button v-else type="primary" @click="goDateil(item)"
                        >Book Now</el-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="hotelData.length == 0 && loading == false">
            <div style="height: 500px; background: #fff">
              <el-empty
                :image-size="250"
                description="There is currently no hotels available"
              ></el-empty>
            </div>
          </template>
        </div>
      </div>
    </div>
    <Footer />

    <!-- 地图弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
    >
      <div>
        {{ currentHotel.latitude }}
        <baidu-map
          class="bd-map"
          :center="{ lng: currentHotel.longitude, lat: currentHotel.latitude }"
          :zoom="18"
          :scroll-wheel-zoom="true"
        >
          <bm-marker
            :position="{
              lng: currentHotel.longitude,
              lat: currentHotel.latitude,
            }"
            :dragging="true"
          >
            <bm-label
              :content="currentHotel.name"
              :labelStyle="{
                borderColor: '#0f46a3',
                color: '#0f46a3',
                fontSize: '10px',
              }"
              :offset="{ width: -35, height: 30 }"
            />
          </bm-marker>
        </baidu-map>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { hotelList, hotelAreaList, defaultFacilities } from "@/api/index";
import logo from "@/components/logo.vue";
import avatar from "@/components/avatar.vue";
export default {
  components: {
    logo,
    avatar,
  },
  data() {
    return {
      center: { lng: 114.172715, lat: 22.313931 },
      zoom: 3,
      dialogVisible: false,
      hotelAreaData: [],
      hotelData: [],
      starData: [
        { name: "2 stars or less", val: "0,1" },
        { name: "3 stars", val: "3" },
        { name: "4 stars", val: "4" },
        { name: "5 stars", val: "5" },
        { name: "Over 5 stars", val: "6" },
      ],
      facilitiesData: [
        "浴缸",
        "洗衣机",
        "厨房",
        "冰箱",
        "空调",
        "智能马桶",
        "阳台",
        "棋牌桌",
        "电脑",
      ],
      loading: false,
      activeName: "xzq",
      barShow: false,
      regionIndex: "",
      starIndex: "",
      facilitiesIndex: "",
      value: 3,
      areaIds: [],
      sortType: 1,
      currentHotel: {},
    };
  },
  mounted() {
    this.getHotelList();
    this.getHotelAreaList();
    this.getFacilities();
  },
  methods: {
    getScoreType(score) {
      if (score > 4.5) {
        return "Very Good";
      } else {
        return "Good";
      }
    },
    closeSearch() {
      this.$refs.searchHotel.close();
    },
    getFacilities() {
      defaultFacilities().then((res) => {
        this.facilitiesData = res.data;
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.currentHotel = {};
    },
    checkMap(item) {
      this.currentHotel = item;
      this.dialogVisible = true;
    },
    sortClick(type) {
      this.sortType = type;
      this.getHotelList();
    },
    clearFilter() {
      this.regionIndex = "";
      this.facilitiesIndex = "";
      this.starIndex = "";
      this.getHotelList();
    },
    getHotelList() {
      this.loading = true;
      let star = [];
      if (this.starIndex != "") {
        star = this.starIndex = "0,1"
          ? this.starIndex.split(",")
          : [this.starIndex];
      }
      let data = {
        sortType: this.sortType,
        rentType: 1,
        areaIds: this.regionIndex ? [this.regionIndex] : [],
        hotelStarSelect: star.length >= 1 ? star : [],
        basicParam: this.facilitiesIndex ? [this.facilitiesIndex] : [],
        ...JSON.parse(sessionStorage.getItem("hotelSearchData")),
      };
      hotelList(data)
        .then((res) => {
          res.data.map((v) => {
            v.hotelStar = Number(v.hotelStar);
          });
          this.hotelData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    getHotelAreaList() {
      let placeProvince = JSON.parse(
        sessionStorage.getItem("hotelSearchData")
      ).placeProvince;
      hotelAreaList({ placeSelectCode: placeProvince })
        .then((res) => {
          this.hotelAreaData = res.data;
        })
        .catch((err) => {
          // this.loading = false;
        });
    },
    tagClose(type) {
      this[type] = "";
      this.getHotelList();
    },
    selectBar() {
      this.barShow = !this.barShow;
    },
    selRegion(id) {
      this.regionIndex = this.regionIndex == id ? "" : id;
      // this.areaIds = [];
      this.getHotelList();
    },
    selStar(id) {
      this.starIndex = this.starIndex == id ? "" : id;
      this.getHotelList();
    },
    selFacilities(i) {
      this.facilitiesIndex = i;
      this.getHotelList();
    },
    goDateil(item) {
      let data = JSON.parse(sessionStorage.getItem("hotelSearchData"));
      console.log(data, "data");
      data.hotelId = item.id;
      sessionStorage.setItem("hotelSearchData", JSON.stringify(data));
      // ?startDate=${data.startDate}&endDate=${data.endDate}&roomNum=${data.roomNum}&personNum=${data.personNum}&placeProvince=${data.placeProvince}&childnNum=${data.childnNum}&hotelId=${item.id}`, // 这里的路径就可以正常的写，不需要添加_blank: true
      let routeUrl = this.$router.resolve({
        path: `/hotel-detail`,
      });
      window.open(routeUrl.href, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.hotel-list {
  background: #f6f7f9;
  .content {
    min-height: 90vh;
    //background: #f5f7fa;
    padding-bottom: 32px;

    .top {
      width: 1440px;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      padding: 16px 0;
      //background: #fff;

      > div {
        //width: 1160px;
        margin: 0 auto;
      }
    }

    .screen {
      width: 1200px;
      background: #fff;
      box-shadow: -1px 2px 6px 0 rgba(15, 41, 77, 0.12);
      border-radius: 2px 2px 0 0;
      margin-bottom: 16px;
      margin: 16px auto 0;
      border-radius: 12px;
      overflow: hidden;

      dl {
        display: flex;
        border-bottom: 1px solid #ffffff;

        &:last-child {
          border: none;
        }

        dt {
          width: 104px;
          background: #e8ebef;
          flex-shrink: 0;
          display: flex;
          justify-content: center;
          padding-top: 16px;
          font-size: 14px;
          color: #0f294d;
          line-height: 18px;
          text-align: center;
          font-weight: 600;
          padding-bottom: 8px;
        }

        dd {
          width: calc(100% - 104px);
          padding: 0 24px 6px;

          .tab-pane {
            padding-top: 16px;
            span {
              display: inline-block;
              font-weight: 400;
              font-size: 12px;
              color: #0a0d1a;
              line-height: 20px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              background: #f5f5f5;
              border-radius: 4px 4px 4px 4px;
              padding: 4px 6px;
              margin-bottom: 8px;

              cursor: pointer;

              &:not(:last-child) {
                margin-right: 12px;
              }
            }

            .sel-filter-item {
              background: #0f46a3;
              color: #fff;
            }
          }

          .star-item {
            margin-top: 8px;
          }

          ::v-deep {
            .el-tabs__nav-wrap::after {
              display: none;
            }
          }
        }
      }

      .advanced-filter {
        dt {
          padding-bottom: 14px;
        }
        .m-tab {
          margin-top: 18px;
        }
        .tab-bar {
          margin-bottom: 14px;
          li {
            font-size: 14px;
            cursor: pointer;
          }
        }

        .bar-active {
          color: #0f46a3;

          i {
            transform: rotate(-180deg);
          }
        }
      }

      .star-filter {
        .m-tab {
          padding-top: 6px;
        }
      }
    }

    .sort {
      width: 1200px;
      margin: 16px auto 4px;
      border-radius: 12px 12px 0px 0px;
      overflow: hidden;

      .active {
        color: #0c50ad;
      }

      .filter {
        padding: 16px 24px;
        background: #fff;
        box-sizing: border-box;
        position: relative;
        border-bottom: 1px solid #eeeeee;

        .el-tag {
          margin-right: 8px;
          margin-bottom: 6px;
        }
      }

      &-box {
        display: flex;
        background: #fff;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 40px;

        div {
          text-align: center;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 100%;
          box-sizing: border-box;
          padding: 0 8px;
          flex-grow: 1;
          font-weight: 400;
          font-size: 12px;
          color: #0a0d1a;
          line-height: 20px;
          letter-spacing: 0;
          text-align: center;
          line-height: 18px;

          &:not(:last-child):after {
            content: "";
            width: 1px;
            height: 100%;
            background: #eee;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: -1px;
          }

          &:hover {
            color: #0f46a3;
          }
        }
      }

      .el-tag {
        border: 1px solid #0c50ad;
        background: #f1f7ff;
        color: #0c50ad;
      }
    }

    .list {
      width: 1200px;
      margin: 0 auto;
      //background: #fff;

      ::v-deep {
        .el-skeleton {
          width: 100%;
          display: flex;
          background: #fff;
        }
      }

      .list-box {
        .list-item {
          padding: 24px;
          background: #fff;
          display: flex;
          box-sizing: border-box;
          width: 100%;
          min-height: 204px;
          margin-bottom: 4px;
          position: relative;

          .topFlag {
            position: absolute;
            top: 0px;
            left: 0;
            background: #e34444;
            color: #fff;
            font-size: 14px;
            text-align: center;
            line-height: 20px;
            padding: 0 6px;
            border-radius: 6px 0 6px 0;
          }

          &:last-child {
            margin-bottom: 0;
          }

          &:hover {
            position: relative;
            box-shadow: 0 8px 10px 0 rgba(15, 41, 77, 0.08);
          }

          &-left {
            width: 252px;
            height: 167px;
            margin-right: 16px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
            }
          }

          &-right {
            width: calc(100% - 252px - 16px);
            display: flex;
            justify-content: space-between;
            .info {
              h2 {
                font-weight: bold;
                font-size: 18px;
                color: #000000;
                line-height: 28px;
                cursor: pointer;
                margin-bottom: 4px;
              }

              .star {
                img {
                  width: 12px;
                  height: 12px;
                  margin-right: 4px;
                }
              }

              .el-rate {
                margin-bottom: 8px;
              }

              .address {
                font-weight: 400;
                font-size: 12px;
                color: #999cac;
                line-height: 20px;
                margin-bottom: 12px;
              }

              .map-btn {
                color: #0f46a3;
                display: inline-block;
                cursor: pointer;
              }

              .tags {
                span {
                  display: inline-block;
                  height: 18px;
                  padding: 2px 4px;
                  font-size: 12px;
                  line-height: 1;
                  color: #ff6f00;
                  border-radius: 2px;
                  border: 1px solid rgba(255, 111, 0, 0.5);
                  box-sizing: border-box;
                  margin-right: 8px;
                  margin-bottom: 4px;
                }
              }
            }

            .room {
              padding-left: 16px;
              min-height: 40px;
              border-left: 2px solid #f5f5f5;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              justify-content: center;

              .name {
                font-size: 14px;
                color: #000000;
                font-weight: 700;
              }

              .hkBedTypes {
                font-size: 14px;
                color: #000000;
                font-weight: 700;
                margin: 10px 0;
              }

              .roomNum {
                color: #f62046;
                font-size: 14px;
                font-weight: 700;
              }
            }

            .btn {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              // justify-content: flex-end;
              // align-items: flex-end;
              .prcie {
                //margin-bottom: 22px;
                display: flex;
                flex-direction: column-reverse;
                margin-right: 28px;
              }
              .discount {
                margin-bottom: 20px;
                margin-top: 10px;
                text-align: end;
              }
              .line-price {
                font-weight: 400;
                font-size: 10px;
                color: #757990;
                line-height: 12px;
                text-decoration: line-through;
                margin-right: 4px;
              }

              .real-price {
                font-weight: 400;
                font-size: 18px;
                color: #f62046;
                line-height: 28px;
                cursor: pointer;

                span {
                  font-size: 12px;
                }
              }

              .price-qi {
                font-weight: 400;
                font-size: 14px;
                color: #0f294d;
                margin-left: 1px;
              }

              .discount-tag {
                padding: 2px 4px;
                line-height: 1;
                box-sizing: border-box;
                background-color: rgba(255, 111, 0, 0.2);
                font-size: 12px;
                margin-bottom: 8px;
                color: #ff6f00;
                border-radius: 2px;
                border: 1px solid rgba(255, 111, 0, 0.3);
                display: inline-flex;
                flex-direction: row-reverse;
                flex-wrap: wrap;
              }

              .detail-btn {
                display: inline-block;
                background: #0f46a3;
                border-radius: 2px;
                font-size: 16px;
                color: #fff;
                text-align: right;
                line-height: 22px;
                padding: 8px 16px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }

  /* 小于1435 px  */
  @media screen and (min-width: 1279px) and (max-width: 1435px) {
    .searchHotel-box {
      width: 985px;
    }

    .top {
      width: 1200px !important;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    .searchHotel-box {
      width: 980px;
    }

    .top {
      width: 1200px !important;
    }
  }

  ::v-deep {
    /* 小于1435 px  */
    @media screen and (min-width: 1279px) and (max-width: 1435px) {
      .li-item-calenda {
        width: 290px !important;
      }

      .li-item-guests {
        padding-left: 28px !important;
        padding-right: 28px !important;
      }
    }

    @media screen and (min-width: 1200px) and (max-width: 1280px) {
      .li-item-calenda {
        width: 290px !important;
      }

      .li-item-guests {
        padding-left: 28px !important;
        padding-right: 28px !important;
      }
    }
  }
}

.bd-map {
  width: 600px;
  height: 600px;
}

.score-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  span:first-child {
    font-size: 14px;
    color: #4978ce;
    line-height: 18px;
  }

  span:last-child {
    background: #4978ce;
    border-radius: 2px;
    padding: 6px 10px;
    margin-left: 8px;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    color: #fff;
  }
}
</style>