<template>
  <div class="header">
    <div class="header-content">
      <div class="header-content-right">
        <img @click="goIndex" src="../../assets/images/logo.png" alt="" />
      </div>

      <div class="header-content-left">
        <ul>
          <li v-if="!token" @click="goLogin">
            <i class="el-icon-user-solid" style="margin-right: 10px"></i>
            Login
          </li>
          <li
            v-if="token"
            class="my"
            @mouseenter="mineMouseenter"
            @mouseleave="mineMouseleave"
          >
            <div>
              <img
                style="width: 28px; height: 28px"
                src="../../assets/images/Group 851@2x.png"
                alt=""
              />
              <!-- <el-avatar icon="el-icon-user-solid" size="small"></el-avatar> -->
            </div>
            <div class="select" v-if="selectShow">
              <div @click="goMine('/mine')">Profile</div>
              <div @click="goMine('/order')">My Bookings</div>
              <!-- <div @click="goMine('/coupon')">Coupon</div> -->
              <div @click="goMine('logout')">Sign Out</div>
            </div>
          </li>
          <!-- <li @click="goOrder" v-if="token">
            <i class="el-icon-tickets"></i>
            My Order
          </li> -->
          <!-- <li
            class="out-login"
            style="margin-left: 10px"
            @click="outLogin"
            v-if="token"
          >
            Logout
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import jsCookie from "js-cookie";
export default {
  data() {
    return {
      token: "",
      selectShow: false,
    };
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
  },
  methods: {
    mineMouseenter() {
      this.selectShow = true;
    },
    mineMouseleave() {
      this.selectShow = false;
    },
    goMine(url) {
      if (url == "logout") {
        this.outLogin();
        return;
      }
      let routeUrl = this.$router.resolve({
        path: url,
      });
      window.open(routeUrl.href, "_blank");
      // this.$router.push(url);
    },
    outLogin() {
      sessionStorage.removeItem("token");
      let fullPath = this.$route.fullPath;
      console.log(fullPath);
      if (fullPath == "/") {
        this.$router.go(0);
        return;
      }
      this.$router.replace("/");
    },
    goLogin() {
      this.$router.push("/login");
    },
    goOrder() {
      this.$router.push("/order");
    },
    goIndex() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  .header-content {
    width: 1160px;
    height: 72px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-right {
      width: 236px;
      height: 52px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &-left {
      height: 100%;
      color: #333;

      .out-login {
        color: #043373;
        font-size: 14px;
        text-decoration: underline;
        line-height: 16px;
        margin-left: 20px;
      }

      ul {
        display: flex;
        align-items: center;
        height: 100%;
      }

      li {
        height: 100%;
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover {
          color: #043373;
        }
      }

      .my {
        display: flex;
        align-items: center;
        margin-right: 12px;
        position: relative;
        z-index: 10;

        &:hove {
        }

        .select {
          position: absolute;
          top: 52px;
          left: -28px;
          width: 130px;
          // height: 50px;
          background: #fff;
          box-shadow: 2px 2px 7px 0px rgb(0, 0, 0, 0.3);
          border-radius: 2px;

          div {
            height: 40px;
            line-height: 40px;
            padding: 0 12px;

            &:hover {
              background: #043373;
              color: #fff;
            }
          }
        }

        .el-avatar {
          margin-right: 6px;
        }
      }
      // font-size: 14px;
    }
  }
}
</style>