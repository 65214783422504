<template>
  <div class="login">
    <Header />
    <div class="content">
      <div class="bg">
        <img src="../../assets/images/loginbg.jpg" alt="" />
      </div>

      <div class="panel">
        <template v-if="registerShow == false && passwordShow == false">
          <p class="title">Email Login</p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="Email" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                show-password
                v-model="ruleForm.password"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm', 'login')"
                style="width: 100%"
                :loading="loading"
                >Login</el-button
              >
            </el-form-item>
          </el-form>
          <p class="tips color-blue" @click="registerShow = true">Register an account</p>
        </template>
        <template v-else-if="registerShow == true && passwordShow == false">
          <p class="title">Email registration</p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="Email" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm', 'register')"
                style="width: 100%"
                >Send Mail</el-button
              >
            </el-form-item>
          </el-form>
          <p class="tips color-blue" @click="registerShow = false">Go Login</p>
        </template>
        <template v-else>
          <p class="title">Password</p>
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-position="left"
          >
            <el-form-item label="Email" prop="email">
              <el-input disabled v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="Code" prop="code">
              <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>
            <el-form-item label="Password" prop="password">
              <el-input
                type="password"
                show-password
                v-model="ruleForm.password"
              ></el-input>
            </el-form-item>
            <el-form-item label="Confirm password" prop="newPassword">
              <el-input
                type="password"
                show-password
                v-model="ruleForm.newPassword"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                @click="submitForm('ruleForm', '')"
                style="width: 100%"
                >Login</el-button
              >
            </el-form-item>
          </el-form>
          <p @click="registerShow = true">Go Login</p>
        </template>
      </div>
    </div>
    <Footer />
  </div>
</template>


<script>
import { registerEmail, registerEmailConfirm, authLogin } from "@/api/index";
export default {
  data() {
    return {
      loading: false,
      registerShow: false,
      passwordShow: false,
      ruleForm: {
        email: "",
        password: "",
        newPassword: "",
        code: ""
      },
      rules: {
        email: [{ required: true, message: "Please enter your email address", trigger: "blur" }],
        password: [{ required: true, message: "Please enter password", trigger: "blur" }],
        newPassword: [
          { required: true, message: "Please enter password", trigger: "blur" },
        ],
        code:[
          { required: true, message: "Please enter password", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    if (this.$route.query.code && this.$route.query.email) {
      this.registerShow = false;
      this.passwordShow = true;
      this.ruleForm.email = this.$route.query.email;
    }
  },
  methods: {
    submitForm(formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
          let promotionCode = sessionStorage.getItem('promotionCode')
          if (type == "register") {
            registerEmail({ email: this.ruleForm.email,promotionCode:promotionCode }).then((res) => {
              this.loading = true;

              if (res.code == 200) {
                this.$message({
                  message: "Email sent successfully, please go to your email to receive the verification code!",
                  type: "success",
                });
                this.registerShow = false
                this.passwordShow = true
              }else {
                this.$message.error(res.msg);
              }
            });
          } else if (type == "login") {
            authLogin({
              username: this.ruleForm.email,
              password: this.ruleForm.password,
            }).then((res) => {
              this.loading = false;

              if (res.code != 200) {
                this.$message.error(res.msg);
              } else {
                sessionStorage.setItem("token", res.data.token);
                this.$router.replace("/");
              }
            }).catch(err => {
              this.loading = false;

            })
          } else {
            if (this.ruleForm.password != this.ruleForm.newPassword) {
              this.$message.error("Two passwords do not match");
              return;
            }
            registerEmailConfirm({
              email: this.ruleForm.email,
              password: this.ruleForm.password,
              code: this.ruleForm.code,
              promotionCode:this.$route.query.promotionCode
            }).then((res) => {
              this.loading = true;

              if (res.code != 200) {
                this.$message.error(res.msg);
              } else {
                sessionStorage.setItem("token", res.data.token);
                this.$router.replace("/");
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  .content {
    min-height: 60vh;
    background: #f5f7fa;
    padding-bottom: 0;
    position: relative;

    .tips {
      font-size: 14px;
      text-decoration: underline;
      cursor: pointer;
    }

    .bg {
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }

    .panel {
      position: absolute;
      top: 20%;
      right: 10%;
      z-index: 100;
      background: #fff;
      width: 350px;
      padding: 30px 30px;
      border-radius: 6px;
      //box-shadow: 1px 2px 6px 3px rgba(255, 255, 255, 0.2);
      box-shadow:  -2px 0px 6px 3px rgba(0, 0, 0, 0.2);
     

      .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 30px;
      }

      ::v-deep {
        .el-form-item__content {
          //display: flex;
        }
      }
    }
  }

  @media screen and (min-width: 1279px) and (max-width: 1435px) {
    .content{
      min-height: 84vh;
    }
  }

  @media screen and (min-width: 1200px) and (max-width: 1280px) {
    .content{
      min-height: 84vh;
    }
  }
}
</style>