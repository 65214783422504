<template>
  <div class="logo" :class="{ 'logo-blue': color }" @click="goIndex">
    <img v-if="!color" src="../assets/images/logo横版.png" alt="" />
    <img v-else src="../assets/images/logo_blue@2x.png" alt="" />
  </div>
</template>


<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
  methods: {
    goIndex() {
      console.log(this.$route.fullPath);
      if (this.$route.fullPath == "/") {
        this.$emit("goIndex");
        return;
      }
      this.$router.push("/");
    },
  },
};
</script>


<style lang="scss" scoped>
.logo {
  display: inline-block;
  //background: rgba(255, 255, 255, 0.1);
  border-radius: 12px 12px 12px 12px;
  padding: 16px;
  cursor: pointer;

  img {
    width: 160px;
    //height: 68px;
  }
}

.logo-blue {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px 12px 12px 12px;
  

  img {
    width: 68px;
    height: 68px;
  }
}
</style>