<template>
  <div class="mine-tab">
    <div id="sideNav" class="aside clearfix">
      <dl
        class="sidenav"
        :class="{ sidenav_c: item.value == tabValue }"
        v-for="(item, index) in tabsList"
        :key="index"
      >
        <dt>
          <div id="my_orders" class="selected" @click="goHref(item.path)">
            <span>{{ item.label }}</span>
          </div>
        </dt>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabValue: {
      type: Number || String,
      default: 0,
    },
  },
  data() {
    return {
      tabsList: [
        {
          value: 0,
          label: "Profile",
          path: "/mine",
        },
        {
          value: 1,
          label: "My Bookings",
          path: "/order",
        },
        {
          value: 2,
          label: "Coupon",
          path: "/coupon",
        },
      ],
    };
  },
  methods: {
    goHref(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.mine-tab {
  .aside {
    width: 148px;
    margin-right: 20px;
    border-top: 1px solid #bed3ee;
    border-right: 1px solid #bed3ee;
    border-bottom: 1px solid #bed3ee;
    border-left: 1px solid #bed3ee;
    background-color: #f7fbff;
  }

  .sidenav {
    background-color: transparent;
    border-bottom: none;
    font-family: PingFangSC, microsoft yahei;
    color: #333;
    height: 40px;

    div {
      display: block;
      padding-left: 4px;
      font-size: 14px;
      color: #333;
      font-size: 14px;
      line-height: 40px;
      padding: 0 10px 0 12px;
      cursor: pointer;
    }
  }

  .sidenav_c {
    background: #bed3ee;

    span {
      color: #0f46a3;
      font-weight: bold;
    }
  }
}
</style>