<template>
  <div class="avatar" :class="{ 'avatar-blue': color }" @click="goMine">
    <img v-if="!token" src="../assets/images/mine_head_nor@2x.png" alt="" />
    <img v-else src="../assets/images/Group 851@2x.png" alt="" />
  </div>
</template>
  

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      token: "",
    };
  },
  mounted() {
    this.token = sessionStorage.getItem("token");
  },
  methods: {
    goMine() {
      if (this.token) {
        let routeUrl = this.$router.resolve({
          path: `/mine`,
        });
        window.open(routeUrl.href, "_blank");
        // this.$router.push("/mine");
        return;
      }
      this.$router.push("/login");
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.avatar {
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px 12px 12px 12px;
  padding: 24px;
  cursor: pointer;

  img {
    width: 48px;
    height: 48px;
  }
}

.avatar-blue {
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
  border-radius: 12px 12px 12px 12px;
}
</style>