<template>
  <div class="search-hotel" @click="close">
    <ul class="search-box">
      <li class="li li-item-address">
        <div class="li-item wave-group">
          <p class="label">Destination</p>
          <div
            class="show-hightlight input"
            :class="{ active: addressShow }"
            @click.stop="inputFocus('address')"
          >
            {{ searchData.cityName }}
          </div>
          <span style="top: 23px" class="bar"></span>
        </div>

        <div class="address-list" :class="{'list-bottom':position == 'top'}" v-if="addressShow">
          <p class="address-list-title">City{{position}}</p>
          <ul class="address-list-ul">
            <li
              v-for="(item, index) in areaData"
              :key="index"
              @click.stop="selectCity(item)"
            >
              {{ item.placeSelectName }}
            </li>
          </ul>
        </div>
      </li>
      <li class="li li-item-calenda">
        <div class="wave-group">
          <div class="time-tab">
            <p class="label">Check-in</p>
            <p class="label"></p>
            <p class="label">Check-out</p>
          </div>
          <el-date-picker
            class="time show-hightlight input"
            :class="{ active: dateShow }"
            v-model="searchData.time"
            :picker-options="pickerOptions"
            type="daterange"
            :range-separator="rangeSeparator()"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :value-format="'yyyy-MM-dd'"
            @focus="dateFocus"
            @blur="dateBlur"
          >
          </el-date-picker>
          <span style="top: 25px" class="bar"></span>
        </div>
      </li>
      <li class="li li-item-guests">
        <div class="li-item wave-group">
          <p class="label">Rooms and Guests</p>
          <div
            class="time show-hightlight input"
            @click.stop="inputFocus('guests')"
            :class="{ active: guestsShow }"
          >
            {{ searchData.roomNum }} Rooms, {{ searchData.personNum }} Adults,
            {{ searchData.childnNum }} Children
          </div>
          <span style="top: 23px; width: 108%" class="bar"></span>
        </div>
        <!-- <div>
          <i
            @click.stop="inputFocus('guests')"
            :style="guestsShow ? 'transform: rotate(-180deg);' : ''"
            class="el-icon-arrow-down"
          ></i>
        </div> -->
        <div class="guests-box" v-if="guestsShow" :class="{'list-bottom':position == 'top'}">
          <ul @click.stop>
            <li>
              <span>Rooms</span>
              <el-input-number
                :min="1"
                max="20"
                size="small"
                v-model="roomNum"
              ></el-input-number>
            </li>
            <li>
              <span
                >Adult <br /><span class="tips">(Over 18 years old)</span></span
              >
              <el-input-number
                size="small"
                v-model="personNum"
                :min="1"
                max="20"
              ></el-input-number>
            </li>
            <li>
              <span
                >Children <br /><span class="tips">(0-17 years old)</span></span
              >
              <el-input-number
                size="small"
                v-model="childnNum"
                :min="0"
                max="20"
              ></el-input-number>
            </li>
          </ul>
          <div class="btn">
            <el-link type="primary" @click="confirmGuests">Confirm</el-link>
          </div>
        </div>
      </li>
      <li class="li li-item-keywords">
        <div class="li-item">
          <p class="label">Keywords (Optional)</p>
          <div class="wave-group">
            <input
              class="input"
              type="text"
              required=""
              placeholder="Hotel Name"
              v-model="searchData.name"
            />
            <!-- <el-input class="show-hightlight input" v-model="input" placeholder="Hotel Name"></el-input> -->
            <span style="top: 23px" class="bar"></span>
          </div>
        </div>
      </li>
      <li class="li li-item-btn" @click="search">
        <i class="el-icon-search"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import { hotelArea } from "@/api/index";
import { getDifferTime } from "@/utils/pub";
export default {
  props: {
    position: {
      type: String,
      default: "bottom",
    },
  },
  data() {
    return {
      areaData: [],
      searchData: {
        cityName: "",
        city: "",
        time: "",
        roomNum: 1,
        personNum: 2,
        childnNum: 0,
        name: "",
      },
      keyword: "",
      roomNum: 1,
      personNum: 2,
      childnNum: 0,
      addressShow: false,
      guestsShow: false,
      dateShow: false,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
    };
  },
  mounted() {
    this.getHotelArea();
    this.getTodayAndTomorrowDate();
  },
  methods: {
    rangeSeparator() {
      if (this.searchData.time.length != 0) {
        return (
          getDifferTime(this.searchData.time[0], this.searchData.time[1]) +
          (getDifferTime(this.searchData.time[0], this.searchData.time[1]) > 1
            ? " nights"
            : " night")
        );
      }
    },
    getTodayAndTomorrowDate() {
      const now = new Date();
      let todayDate = this.formatDate(now);
      let tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24小时*60分钟*60秒*1000毫秒
      let tomorrowDate = this.formatDate(tomorrow);
      this.searchData.time = [todayDate, tomorrowDate];
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    // 获取地址
    getHotelArea() {
      hotelArea().then((res) => {
        this.areaData = res.data;
        this.searchData.cityName = this.areaData[0].placeSelectName;
        this.searchData.city = this.areaData[0].placeSelectCode;
        let hotelSearchData = JSON.parse(
          sessionStorage.getItem("hotelSearchData")
        );
        if (hotelSearchData.placeProvince) {
          let arr = this.areaData.find(
            (v) => v.placeSelectCode == hotelSearchData.placeProvince
          );
          this.searchData.cityName = arr.placeSelectName;
          this.searchData.city = arr.placeSelectCode;

          this.searchData.time = [
            hotelSearchData.startDate,
            hotelSearchData.endDate,
          ];

          this.searchData.roomNum = hotelSearchData.roomNum;
          this.roomNum = hotelSearchData.roomNum;
          this.searchData.personNum = hotelSearchData.personNum;
          this.personNum = hotelSearchData.personNum;
          this.searchData.childnNum = hotelSearchData.childnNum;
          this.childnNum = hotelSearchData.childnNum;

          this.searchData.name = hotelSearchData.name;
        }
      });
    },
    // 选择地址
    selectCity(item) {
      this.searchData.city = item.placeSelectCode;
      this.searchData.cityName = item.placeSelectName;
      this.addressShow = false;
    },
    confirmGuests() {
      this.searchData.roomNum = this.roomNum;
      this.searchData.personNum = this.personNum;
      this.searchData.childnNum = this.childnNum;
      this.guestsShow = false;
    },
    dateFocus(e) {
      this.dateShow = true;
    },
    dateBlur(e) {
      this.dateShow = false;
    },
    close() {
      this.searchData.roomNum = this.roomNum;
      this.searchData.personNum = this.personNum;
      this.searchData.childnNum = this.childnNum;
      this.addressShow = false;
      this.guestsShow = false;
    },
    inputFocus(type) {
      if (type === "address") {
        this.addressShow = true;
      } else if (type == "guests") {
        this.guestsShow = !this.guestsShow;
      }
    },
    search() {
      let data = JSON.parse(JSON.stringify(this.searchData));
      data.startDate = data.time[0];
      data.endDate = data.time[1];
      data.name = data.name == undefined ? "" : data.name;

      let sessionStorageData = {
        startDate: data.startDate,
        endDate: data.endDate,
        placeProvince: data.city,
        roomNum: data.roomNum,
        personNum: data.personNum,
        childnNum: data.childnNum,
        name: data.name,
      };
      sessionStorage.setItem(
        "hotelSearchData",
        JSON.stringify(sessionStorageData)
      );
      if (this.$route.name == "HotelList") {
        this.$router.replace(
          // ?startDate=${data.startDate}&endDate=${data.endDate}&placeProvince=${data.city}&roomNum=${data.roomNum}&personNum=${data.personNum}&childnNum=${data.childnNum}&name=${data.name}
          `/hotel-list`
        );
        this.$router.go(0);
        return;
      }
      this.$router.push(`/hotel-list`);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-hotel {
  background: #fff;
  border-radius: 12px;
  //height: 100px;
  //overflow: hidden;

  .search-box {
    display: flex;
    .li {
      //height: 102px;
      //border: 1px solid #dadfe6;
      padding: 24px 0;
      &:first-child {
        width: calc(180px - 36px);
        border-right: none;
        border-radius: 12px 0 0 0;
        border-right: 1px solid #eeeeee;
        padding-left: 36px;
      }

      &:nth-child(2) {
        width: calc(424px - 48px);
        border-right: none;
        padding-left: 24px;
        padding-right: 24px;
        border-right: 1px solid #eeeeee;
      }

      &:nth-child(3) {
        width: calc(300px - 54px - 40px);
        border-right: none;
        padding-right: 40px;
        padding-left: 54px;

        padding-right: 42px;
        padding-left: 40px;

        border-right: 1px solid #eeeeee;
      }

      &:nth-child(4) {
        width: calc(203px - 24px);
        padding-left: 24px;
        border-right: none;
      }

      &:last-child {
        width: 102px;
        border-radius: 0 12px 12px 0;
      }

      .li-item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
        color: #999cac;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      .show-hightlight {
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        line-height: 24px;
        font-style: normal;
        text-transform: none;
        //line-height: 22px;
      }

      .nights {
        font-size: 14px;
        color: #0f294d;
        line-height: 18px;
      }
    }

    .li-item-address {
      position: relative;
      .address-list {
        position: absolute;
        left: -1px;
        top: 82px;
        width: 202px;
        background: #fff;
        border: 1px solid #dadfe6;
        z-index: 100;
        &-title {
          font-size: 14px;
          color: #666;
          background: #f7f7fb;
          height: 30px;
          line-height: 30px;
          padding-left: 8px;
          font-weight: 700;
          padding-left: 16px;
        }

        &-ul {
          display: flex;
          flex-direction: column;
          overflow: auto;
          max-height: 155px;
          position: relative;
          z-index: 100;

          li {
            height: 40px;
            line-height: 40px;
            padding-left: 16px;
            cursor: pointer;

            &:hover {
              background: #0f46a3;
              color: #fff;
            }
          }
        }
      }
    }

    .li-item-calenda {
      //display: flex;
      justify-content: space-between;
      align-items: center;

      .time-tab {
        width: 100%;
        //height: 100%;
        display: flex;
        //flex-direction: column;
        justify-content: space-between;
      }

      .checkout {
        text-align: end;

        .input {
          padding-right: 0 !important;
          text-align: end !important;
        }
      }

      ::v-deep {
        .el-range-editor.el-input__inner {
          border: none;
          padding: 0;
          height: 30px;
        }
        .el-date-editor {
          width: 100%;
          display: flex !important;
          justify-content: space-between;
        }
        .el-range-separator {
          width: 100px;
        }
        .el-range-input {
          width: 100px;
          font-weight: 500;
          font-size: 16px;
          color: #000000;
          line-height: 24px;
          text-align: left;
          font-style: normal;
          text-transform: none;
          &:nth-child(2) {
            text-align: start;
          }
          text-align: end;
        }
        .el-range-separator {
          font-size: 14px;
          color: #8592a6;
          line-height: 18px;
          -webkit-user-select: none;
          -moz-user-select: none;
          user-select: none;
        }
      }
    }

    .li-item-guests {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .guests-box {
        position: absolute;
        left: -1px;
        top: 82px;
        width: 100%;
        background: #fff;
        border: 1px solid #dadfe6;
        z-index: 1;

        ul {
          padding-top: 12px;
        }

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 16px 12px;
          color: #0f294d;
          font-size: 16px;

          .tips {
            display: block;
            font-size: 12px;
            line-height: 1;
            color: #6b8299;
            margin-top: 6px;
          }
        }

        .btn {
          text-align: end;
          padding: 16px;
        }
        .el-link {
        }
      }
    }

    .li-item-keywords {
      input::placeholder {
        color: #8592a6;
        font-size: 14px; /* 更改placeholder的文本大小 */
        font-weight: 700;
      }

      ::v-deep {
        .el-input__inner {
          padding: 0;
          border: none;
          height: 38px;
        }
      }
    }

    .li-item-btn {
      background: #0c50ad;
      color: #fff;
      font-size: 40px;
      text-align: center;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      //height: calc(102px - 46px);
      //line-height: 54px;
    }

    .list-bottom{
      top: -173px !important;
    }

    .wave-group {
      position: relative;
    }

    .wave-group .input {
      font-size: 14px;
      //padding: 10px 10px 10px 0;
      display: block;
      //width: 200px;
      border: none;
      background: transparent;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .wave-group .input:focus {
      outline: none;
    }

    .wave-group .bar {
      position: relative;
      display: block;
      // width: 200px;
    }

    .wave-group .bar:before,
    .wave-group .bar:after {
      content: "";
      height: 2px;
      width: 0;
      bottom: 0px;
      position: absolute;
      background: #5264ae;
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }

    .wave-group .bar:before {
      left: 50%;
    }

    .wave-group .bar:after {
      right: 50%;
    }

    .wave-group .input:focus ~ .bar:before,
    .wave-group .input:focus ~ .bar:after {
      width: 50%;
    }

    .active ~ .bar:before,
    .active ~ .bar:after {
      width: 50%;
    }
  }

  ::v-deep {
    .el-date-editor {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>