<template>
  <div class="room">
    <div class="room-close">
      <i class="el-icon-close" @click="close"></i>
    </div>

    <div class="scroll-wraper">
      <div class="m-album-main">
        <div class="article-box">
          <div class="m-arrow">
            <i class="el-icon-arrow-left" @click="prev"></i>
          </div>
          <div class="m-center-box">
            <div class="m-title">
              {{ roomDetails.nameCn }}({{ roomDetails.nameEn }})
            </div>
            <div class="big-img">
              <img
                :src="
                  imgUrlIndex == ''
                    ? roomDetails.imageUrl[0]
                    : roomDetails.imageUrl[imgUrlIndex]
                "
                alt=""
              />
              <div class="m-imgInfo">
                {{ imgUrlIndex == "" ? 1 : imgUrlIndex + 1 }}
                / {{ roomDetails.imageUrl.length }}
              </div>
            </div>
          </div>
          <div class="m-arrow">
            <i class="el-icon-arrow-right" @click="next"></i>
          </div>
        </div>
        <div class="m-album-aside">
          <div class="m-aside-scrollable">
            <h2>Basic information</h2>
            <div class="m-aside-content">
              <div class="icon-with-text-fullLine">
                <i class="icon iconfont icon-mianji"></i>
                <span class="icon-content">{{ roomDetails.size }} square meter</span>
              </div>
              <div class="icon-with-text-fullLine">
                <i class="icon iconfont icon-chuang"></i>
                <span class="icon-content"
                  >{{ roomDetails.allowPersonNum }} people</span
                >
                <span class="icon-content">{{
                  bedType(roomDetails.addBedType)
                }}</span>
              </div>

              <div class="icon-with-text-fullLine">
                <i class="icon iconfont icon-louti"></i>
                <span class="icon-content">{{ roomDetails.roomFloor }}nd floor</span>
              </div>
            </div>
            <div class="m-room-facility">
              <h2 style="margin-bottom: 12px">All facilities</h2>
              <ul>
                <li
                  v-for="(item, index) in roomDetails.roomServices"
                  :key="index"
                >
                  <div>
                    <h3 class="m-facility">{{ item.name }}</h3>
                    <div class="m-aside-content">
                      <template v-for="(subItem, subIndex) in item.children">
                        <div
                          class="icon-with-text"
                          :key="subIndex"
                          v-if="subItem.available"
                        >
                          <i class="icon iconfont icon-tick"></i>
                          <span>{{ subItem.name }}</span>
                        </div>
                      </template>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="m-policy-room">
              <h2 style="margin-bottom: 12px">Check-in/check-out time</h2>
              <p>Check-in time：{{ roomDetails.checkTime }}</p>
              <p>Check-out time：{{ roomDetails.outTime }}</p>
            </div>
            <div class="m-policy-room">
              <h2 style="margin-bottom: 12px">Cancel Change Clause</h2>
              <p>
                Please pay attention to the cancellation policy displayed on the room page for the cost of canceling the room. If you need our help, please contact 
                18600248705
              </p>
            </div>
          </div>

          <div class="select-room">
            <el-button type="primary" @click="close">Select room</el-button>
          </div>
        </div>
      </div>

      <div class="m-album-nav">
        <ul class="m-album-nav-img">
          <li
            v-for="(item, index) in roomDetails.imageUrl"
            :key="index"
            @click="selImg(item, index)"
            :class="{'li-active':index == imgUrlIndex}"
          >
            <img :src="item" alt="" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roomDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      imgUrlIndex: "",
    };
  },
  methods: {
    next() {
      if (this.imgUrlIndex < this.roomDetails.imageUrl.length - 1) {
        this.imgUrlIndex++;
      }
    },
    prev() {
      console.log(this.imgUrlIndex);
      if (this.imgUrlIndex > 0) {
        this.imgUrlIndex--;
      }
    },
    bedType(type) {
      //1不可加床 2免费加床 3付费加床
      let res = "";
      if (type === 1) {
        res = "No additional beds allowed";
      } else if (type === 2) {
        res = "Free extra bed";
      } else if (type === 3) {
        res = "Paid extra bed";
      }
      return res;
    },
    selImg(item, index) {
      this.imgUrlIndex = index;
    },
    close() {
      this.$emit("close", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.room {
  background-color: #000;
  position: fixed;
  height: 100%;
  width: 100%;
  color: #fff;
  top: 0;
  left: 0;
  z-index: 1000;

  .room-close {
    background-color: #000;
    font-size: 26px;
    top: 6px;
    right: 10px;
    cursor: pointer;
    z-index: 100;
    padding: 10px;
    position: fixed;
  }

  .scroll-wraper {
    //display: flex;
    justify-content: space-between;
    width: 97%;
    height: 97%;
    padding: 1.5%;
    flex-direction: column;

    .m-album-main {
      padding-bottom: 12px;
      height: 74%;
      display: flex;

      .article-box {
        height: 100%;
        display: flex;
        align-items: center;
        .m-arrow {
          font-size: 40px;
          cursor: pointer;
        }

        .m-center-box {
          height: 100%;
          margin: 0 24px;

          .m-title {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 24px;
            line-height: 38px;
          }

          .big-img {
            position: relative;
            width: 744px;
            height: calc(100% - 62px);
            //background: #fff;

            .m-imgInfo {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              font-size: 12px;
              background-image: linear-gradient(
                180deg,
                transparent,
                rgba(15, 41, 77, 0.5)
              );
              text-align: end;
              padding: 12px;
            }

            img {
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
              width: 100%;
              height: 495px;
              object-fit: cover;
              object-position: 50% 50%;
            }
          }
        }
      }

      .m-album-aside {
        //height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .m-aside-scrollable {
          height: calc(100% - 80px);
          overflow-y: auto;
          scrollbar-width: thin;
          scrollbar-color: hsla(0, 0%, 100%, 0.6) hsla(0, 0%, 100%, 0.2);
        }
        .select-room {
          margin-top: 40px;
        }
        h2 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 24px;
          line-height: 38px;
        }

        .m-aside-content {
          font-size: 14px;
          .icon-with-text-fullLine {
            width: 100%;
            display: flex;
            margin-bottom: 12px;
            i {
              //font-size: 24px;
              margin-right: 8px;
            }

            .icon-content {
              margin-right: 8px;
            }
          }
        }

        .m-room-facility {
          .m-facility {
            font-size: 18px;
            line-height: 24px;
            margin: 8px 0;
          }

          .m-aside-content {
            .icon-with-text {
              font-size: 14px;
              width: 45%;
              display: inline-flex;
              word-break: break-word;
              margin: 8px 5% 8px 0;
              vertical-align: top;
              line-height: 22px;
            }
          }
        }

        .m-policy-room {
          p {
            font-size: 16px;
            margin: 10px 0;
            line-height: 24px;
          }
        }
      }
    }

    .m-album-nav {
      .m-album-nav-img {
        display: flex;
        margin: 30px 30px 0;
        overflow-x: auto;
        overflow-y: hidden;

        li {
          display: inline-block;
          width: 120px;
          height: 80px;
          border: 2px solid #000;
          cursor: pointer;
          background-size: cover;
        }

        .li-active{
          border-color: #fff;
        }

        li ~ li {
          margin-left: 4px;
        }

        img {
          width: 120px;
          height: 80px;
        }
      }
    }
  }
}
</style>