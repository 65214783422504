<template>
  <div class="coupon">
    <Header />
    <div class="content">
      <div class="panel">
        <MineTab :tabValue="2" />
        <div class="coupon-box">
          <p class="dy">
            <el-button @click="getCoupon">Coupon Mall</el-button>
            <el-button @click="dialogVisible = true" type="primary"
              >Redeem Coupons</el-button
            >
          </p>

          <div class="coupon-list" v-if="ticketData.length != 0">
            <ul class="coupon-list-box">
              <li
                style="width: 50%"
                v-for="(item, index) in ticketData"
                :key="index"
              >
                <div class="coupon-list-item">
                  <!-- <p class="type">
                    <span>{{ getTicketType(item.ticketType) }}</span>
                    <i class="el-icon-caret-right"></i>
                  </p> -->
                  <p class="type">
                    <span class="type-right">
                      <span>{{ getTicketType(item.ticketType) }}</span>
                      <i class="el-icon-caret-right"></i>
                    </span>

                    <span class="type-left" :style="item.hasUsed ? 'opacity: 0.3;' : item.hasExpire?'opacity: 0.3;':''">{{item.hasUsed?'Already Used':item.hasExpire?'Expired':'Ready For Use'}}</span>
                  </p>
                  <div class="p12 coupon-list-item--info">
                    <p class="price">
                      <span v-if="item.ticketType == 1 || item.ticketType == 4"
                        >Over {{ item.conditions }}</span
                      >
                      <template
                        v-if="
                          item.ticketType == 1 ||
                          item.ticketType == 2 ||
                          item.ticketType == 4
                        "
                      >
                        - {{ item.preferential }}
                        {{ item.ticketType == 4 ? " days" : " yuan" }}
                      </template>
                      <template v-if="item.ticketType == 3">
                        -{{ item.preferential }}%
                      </template>
                    </p>
                    <p class="name">
                      <span>{{ item.enTicketName }}</span>
                      <span
                        >Validity Period：{{ item.effectDate }} -
                        {{ item.expireDate }}</span
                      >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="item.scopeName"
                        placement="top-start"
                      >
                        <span class="applicable"
                          >Applicable To：{{ item.scopeName }}</span
                        >
                      </el-tooltip>
                    </p>
                    <div class="circle"></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="coupon-list" style="justify-content: center" v-else>
            <div>
              <el-empty
                description="There are currently no coupons available"
              ></el-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <loading :show="loadingShow" />

    <!-- 兑换码 -->
    <el-dialog
      title="Redeem Code"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item label="Code" prop="code">
          <el-input v-model="ruleForm.code"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="Redeem">Redeem</el-button>
      </span>
    </el-dialog>

    <!-- 优惠券商城 -->
    <el-drawer
      title="Coupon Mall"
      :visible.sync="drawer"
      :direction="direction"
      :before-close="handleClose"
      size="500px"
    >
      <div class="coupon-mall">
        <div class="coupon-list" v-if="couponList.length != 0">
          <ul class="coupon-list-box">
            <li
              style="width: 100%"
              v-for="(item, index) in couponList"
              :key="index"
            >
              <div class="coupon-list-item">
                <p class="type">
                  <span class="type-right">
                    <span>{{ getTicketType(item.ticketType) }}</span>
                    <i class="el-icon-caret-right"></i>
                  </span>
                  <span class="type-left" @click="receiveCoupon(item)" :style="!item.canReceive ? 'opacity: 0.3;' : ''">{{
                    item.canReceive ? "Receive" : "Received"
                  }}</span>
                </p>
                <div class="p12 coupon-list-item--info">
                  <p class="price">
                    <span v-if="item.ticketType == 1 || item.ticketType == 4"
                      >Over {{ item.conditions }}</span
                    >
                    <template
                      v-if="
                        item.ticketType == 1 ||
                        item.ticketType == 2 ||
                        item.ticketType == 4
                      "
                    >
                      - {{ item.preferential }}
                      {{ item.ticketType == 4 ? " days" : " yuan" }}
                    </template>
                    <template v-if="item.ticketType == 3">
                      -{{ item.preferential }}%
                    </template>
                  </p>
                  <p class="name">
                    <span>{{ item.enTicketName }}</span>
                    <span
                      >Validity Period：{{ item.effectDate }} -
                      {{ item.expireDate }}</span
                    >
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="item.scopeName"
                      placement="top-start"
                    >
                      <span class="applicable"
                        >Applicable To：{{ item.scopeName }}</span
                      >
                    </el-tooltip>
                  </p>
                  <div class="circle"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
  
<script>
import MineTab from "@/components/MineTab/mineTab.vue";
import {
  getMyTicket,
  codeRedeem,
  ticketPage,
  ticketReceive,
} from "@/api/index";
export default {
  components: {
    MineTab,
  },
  data() {
    return {
      loadingShow: false,
      ticketData: [],
      dialogVisible: false,
      ruleForm: {
        code: "",
      },
      rules: {
        code: [{ required: true, message: "Enter the code", trigger: "blur" }],
      },
      drawer: false,
      direction: "rtl",
      couponList: [],
    };
  },
  mounted() {
    this.getTicket();
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      this.drawer = false
      this.ruleForm.code = "";
    },
    Redeem() {
      codeRedeem({ redeemCode: this.ruleForm.code }).then((res) => {
        console.log(res, "res");
        if (res.code == 200) {
          this.$message.success("Redemption successful");
          this.dialogVisible = false;
          this.ruleForm.code = "";
          this.getTicket();
        } else {
          this.$message.error(res.msg);
          this.dialogVisible = false;
          this.ruleForm.code = "";
        }
      });
    },
    receiveCoupon(item) {
      if (item.canReceive) {
        ticketReceive({ ticketId: item.id }).then((res) => {
          if (res.code == 200) {
            this.$message.success("Successfully claimed");
            this.drawer = false;
            this.getTicket();
          }
        });
      }
    },
    // redeemCode
    getTicketType(type) {
      if (type == 1) {
        return "Full Reduction";
      } else if (type == 2) {
        return "Direct Reduction";
      } else if (type == 3) {
        return "Discount";
      } else {
        return "Gifts";
      }
    },
    getCoupon() {
      ticketPage({ page: { page: 1, size: 10 } }).then((res) => {
        if (res.code == 200) {
          this.couponList = res.data.records;
          this.drawer = true;
        }
      });
    },
    getTicket() {
      this.loadingShow = true;
      getMyTicket()
        .then((res) => {
          this.ticketData = res.data;
          this.loadingShow = false;
        })
        .catch((err) => {
          this.loadingShow = false;
        });
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.coupon {
  .content {
    min-height: 60vh;
    background: #f5f7fa;
    padding-bottom: 32px;

    .panel {
      width: 1160px;
      margin: 0 auto;
      padding-top: 24px;
      display: flex;

      .coupon-box {
        width: calc(100% - 148px);
        padding: 18px;
        height: 60vh;
        background: #fff;

        .dy {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          //text-align: center;
          //text-decoration: underline;
          cursor: pointer;
          margin-bottom: 32px;

          .el-button {
            position: relative;
            left: -10px;
          }
        }
      }
    }
  }

  .coupon-list {
    width: 100%;
    height: 70%;
    overflow: auto;
    display: flex;

    .coupon-list-box {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-content: flex-start;
    }

    .coupon-list-item {
      margin-right: 12px;
      align-items: center;
      background: linear-gradient(to right, #7992c4, #043373);
      min-height: 82px;
      margin-bottom: 8px;

      .coupon-list-item--info {
        position: relative;
        display: flex;
        align-items: center;
        min-height: 82px;
        padding-right: 0;
        padding-left: 0;
      }

      .applicable {
        overflow: hidden; // 溢出的内容隐藏
        text-overflow: ellipsis; // 溢出的部分用省略号...显示
        -webkit-line-clamp: 2; // 行数
        display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示
        -webkit-box-orient: vertical; // 设置或检索伸缩盒对象的子元素的排列方式
      }
      .type {
        //width: 75px;
        height: 32px;
        //border-radius: 0 50px 50px 0;
        line-height: 100px;
        background: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;

        span {
          display: block;
          //width: 10px;
          font-size: 18px;
          color: #fff;
          font-weight: bold;
          //width: 75px;
          line-height: 15px;
          word-wrap: break-word;
        }
      }

      .type {
        padding: 12px 16px;
        justify-content: space-between;
        .type-right {
          display: flex;
        }
        .type-left {
          cursor: pointer;
        }
      }
      .price {
        height: 64px;
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        border-right: 1px dashed #ccc;
        line-height: 64px;
        padding: 0 6px;
      }

      .name {
        font-size: 12px;
        font-weight: 500;
        padding: 0 18px;
        color: #fff;
        display: flex;
        flex-direction: column;
        width: 210px;

        span {
          margin-bottom: 6px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .circle {
        position: absolute;
        right: 0;
        width: 14px;
        height: 24px;
        border-radius: 50px 0 0 50px;
        line-height: 100px;
        background: #fff;
      }
    }
  }

  .coupon-mall {
    padding: 0 24px;
    .coupon-list {
      height: 90vh !important;

      .coupon-list-item {
        margin-right: 0;
      }

      .type {
        padding: 12px 16px;
        justify-content: space-between;
        .type-right {
          display: flex;
        }
        .type-left {
          cursor: pointer;
        }
      }

      .price{
        padding-left: 12px;
      }
    }

    .coupon-list::-webkit-scrollbar {
      display: none; /* 针对Webkit浏览器 */
    }
  }

  ::v-deep{
    .el-drawer__header{
      font-size: 18px;
      color: #000;
      margin-bottom: 20px;
    }
  }
}
</style>