import request from '@/utils/request'

// 首页获取地址
export function hotelArea(data) {
  return request({
    url: `/hk/hotel/area`,
    method: 'get',
    data
  })
}

// 推荐酒店
export function hotelRecommended(data) {
  return request({
    url: `/hk/hotel/recommendedHotel`,
    method: 'post',
    data
  })
}

// 搜索酒店
export function hotelList(data) {
  return request({
    url: "/hk/hotel/app/list",
    method: 'post',
    data
  })
}

// 获取行政区
export function hotelAreaList(data) {
  return request({
    url: "/hk/area/list",
    method: 'post',
    data
  })
}

// 酒店详情
export function hotelHotelDetail(data) {
  return request({
    url: `/hk/hotel/get/${data.id}`,
    method: 'get',
    data
  })
}

// 房型列表
export function hotelRoomList(data) {
  return request({
    url: `/hk/room/type/app/list`,
    method: 'post',
    data
  })
}

// 房型详情
export function hotelRoomType(data) {
  return request({
    url: `/hk/room/type/get/${data.id}`,
    method: 'get',
    data
  })
}

// 酒店详情最低价格
export function hoteWeblRoomList(data) {
  return request({
    url: `/hk/web/room/type/app/list`,
    method: 'post',
    data
  })
}


// 获取酒店下单费用明细
export function hotelPreOrder(data) {
  return request({
    url: `/hk/order/pre/order/list`,
    method: 'post',
    data
  })
}


// 酒店订单付款
export function hotelOrderAdd(data) {
  return request({
    url: `/hk/order/hotel/add`,
    method: 'post',
    data
  })
}

// 订单列表
export function hotelOrders(data) {
  return request({
    url: `/hk/order/user/list`,
    method: 'post',
    params: data
  })
}


// 订单详情
export function hotelOrderDetail(data) {
  return request({
    url: `/hk/order/get/${data.id}`,
    method: 'get',
  })
}

// 订单付款
export function hotelPay(data) {
  return request({
    url: `/hk/order/hotel/pay`,
    method: 'post',
    data
  })
}

// 注册发送邮件
export function registerEmail(data) {
  return request({
    url: `/hk/auth/register/email`,
    method: 'get',
    params: data
  })
}

// 注册确认
export function registerEmailConfirm(data) {
  return request({
    url: `/hk/auth/register/email/confirm`,
    method: 'post',
    data
  })
}

// 登录
export function authLogin(data) {
  return request({
    url: `/auth/login`,
    method: 'post',
    data
  })
}

export function defaultFacilities(data) {
  return request({
    url: `/hk/hotel/defaultFacilities`,
    method: 'get',
    data
  })
}

// 酒店详情
export function hotelDetail(data) {
  return request({
    url: `/hk/hotel/get/${data.id}`,
    method: 'get',
  })
}

// 我的优惠券
export function getMyTicket(data) {
  return request({
    url: `/hk/discount/ticket/myTicket`,
    method: 'get',
  })
}

// 个人中心
export function userCurrent(data) {
  return request({
    url: `hk/api/user/current`,
    method: 'get',
  })
}




export function codePage(data) {
  return request({
    url: `hk/promotion/code/page`,
    method: 'POST',
    data,
  })
}


export function promotionCodeCounts(data) {
  return request({
    url: `/hk/promotion/code/counts/${data.code}`,
    method: 'POST',
    data,
  })
}

// 兑换优惠券
export function codeRedeem(data) {
  return request({
    url: `/hk/ticket/redeem/code/redeem`,
    method: 'POST',
    data,
  })
}


// 优惠券商城
export function ticketPage(data) {
  return request({
    url: `/hk/discount/ticket/page`,
    method: 'POST',
    data,
  })
}

// 领取优惠券
export function ticketReceive(data) {
  return request({
    url: `/hk/discount/ticket/receive`,
    method: 'POST',
    data,
  })
}

// 获取评论
export function commentList(data) {
  return request({
    url: `/hk/comment/list`,
    method: 'POST',
    data,
  })
}

// 获取图片
export function fileGet(data) {
  return request({
    url: `/hk/web/file/get/ids`,
    method: 'POST',
    data,
  })
}
