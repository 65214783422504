<template>
  <div class="order-detail">
    <Header />
    <div class="content">
      <div class="panel">
        <div class="m-navigation">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/order' }"
              >Hotel orders</el-breadcrumb-item
            >
            <el-breadcrumb-item>Order details</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="g-grid">
          <div class="g-grid-left">
            <div class="m-order-status">
              <div class="m-orderProcessStatus warning">
                <i
                  class="el-icon-warning"
                  v-if="
                    orderData.status == 1 ||
                    orderData.status == 4 ||
                    orderData.status == 6 ||
                    orderData.status == 10
                  "
                ></i>

                <i
                  class="el-icon-error"
                  style="color: #757990"
                  v-if="
                    orderData.status == 8 ||
                    orderData.status == 7 ||
                    orderData.status == 5 ||
                    orderData.status == 9
                  "
                ></i>

                <i
                  class="el-icon-success"
                  style="color: #52af6f"
                  v-if="orderData.status == 2 || orderData.status == 3"
                ></i>
                <template>
                  <span
                    v-if="orderData.status == 1"
                    class="order-price-status-title"
                    >Unpaid</span
                  >
                  <span
                    v-if="orderData.status == 2"
                    class="order-price-status-title"
                    style="color: #52af6f"
                    >Paid</span
                  >
                  <span
                    v-if="orderData.status == 3"
                    class="order-price-status-title"
                    style="color: #52af6f"
                    >Determined</span
                  >
                  <span
                    v-if="orderData.status == 4"
                    class="order-price-status-title color-grey"
                    >Pending refund</span
                  >
                  <span
                    v-if="orderData.status == 5"
                    class="order-price-status-title color-grey"
                    >Refund successful</span
                  >
                  <span
                    v-if="orderData.status == 6"
                    class="order-price-status-title color-grey"
                    >Unpaid canceled</span
                  >
                  <span
                    v-if="orderData.status == 7"
                    class="order-price-status-title color-grey"
                    >Payment failed</span
                  >
                  <span
                    v-if="orderData.status == 8"
                    class="order-price-status-title color-grey"
                    >Order closed</span
                  >
                  <span
                    v-if="orderData.status == 9"
                    class="order-price-status-title color-grey"
                    >Refund failed</span
                  >
                  <span
                    v-if="orderData.status == 10"
                    class="order-price-status-title color-grey"
                    style="color: #f5594a"
                    >Refunding</span
                  >
                </template>
              </div>

              <div class="m-orderStatus_content">
                <span class="m-order-numberText">OrderId</span>
                <span class="m-order-number">{{ orderData.id }}</span>
              </div>

              <div class="m-orderStatus-btn">
                <!-- <el-button type="primary" plain v-if="orderData.status == 2"
                  >Cancellation of order</el-button
                > -->
                <el-button type="primary" plain @click="ReserveClick"
                  >Booking again</el-button
                >
                <el-button
                  type="primary"
                  v-if="orderData.status == 1"
                  @click="payFun(item)"
                  >Go pay</el-button
                >
              </div>
            </div>

            <div class="m-roomInfo">
              <div class="m-module-normal_content" v-if="orderData.roomInfoVOS">
                {{ orderData.roomInfoVOS[0].roomNameEn }} <span class="f14">（{{ orderData.roomInfoVOS[0].roomName }}）</span>
              </div>
              <div class="m-roomInfo_detail" v-if="orderData.checkDate">
                <div class="g-flex">
                  <div class="m-checkInOut">
                    <div class="m-checkInOut_time">
                      <div class="timeLable">Check In</div>
                      <div class="time">{{ orderData.checkDate }}</div>
                    </div>
                    <div class="m-checkInOut_qty">
                     {{rangeSeparator([orderData.checkDate,orderData.outDate])}}
                    </div>
                    <div class="m-checkInOut_time checkOut">
                      <div class="timeLable">Check Out</div>
                      <div class="time">{{ orderData.outDate }}</div>
                    </div>
                  </div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Number of rooms</div>
                  <div class="g-flex_col1">{{ orderData.roomNum }}</div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Guest Name</div>
                  <div class="g-flex_col1">
                    {{ orderData.roomInfoVOS[0].guestFirstName }}/{{
                      orderData.roomInfoVOS[0].guestLastName
                    }}
                  </div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Check In</div>
                  <div class="g-flex_col1">{{ orderData.checkDate }}</div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Check Out</div>
                  <div class="g-flex_col1">{{ orderData.outDate }}</div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Notes</div>
                  <div class="g-flex_col1">{{ orderData.remark }}</div>
                </div>
              </div>
            </div>

            <div class="m-concatInfo">
              <div class="m-module-normal_content">Contacts</div>
              <div class="m-concatInfo_content">
                <div class="g-flex">
                  <div class="roomLable">Email</div>
                  <div class="g-flex_col1">{{ orderData.contactsEmail }}</div>
                </div>
                <div class="g-flex">
                  <div class="roomLable">Telephone</div>
                  <div class="g-flex_col1">{{ orderData.contactsPhone }}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="g-grid-right">
            <div class="m-hotelInfo">
              <div class="m-hotelInfo-photo">
                <img
                  src="http://dimg04.c-ctrip.com/images/1mc1112000bpkwrkf289E_C_400_600_R5_Q70.jpg"
                  alt=""
                />
              </div>

              <div class="m-hotelInfo-desc">
                <div class="hotelName">
                  {{ orderData.hotelNameEn }}
                </div>
                <div class="m-hotelInfo-address">
                  {{ orderData.placeCountry }} {{
                    orderData.placeProvince
                  }} {{ orderData.placeCity }} {{ orderData.placeInfoEn }}
                </div>
              </div>
            </div>
            <div class="m-roomProps">
              <template v-for="(item, index) in orderData.roomInfoVOS">
                <div class="m-roomProps_title" :key="index">
                  {{ item.roomNameEn }}
                </div>
                <ul class="propsList" :key="index">
                  <li class="propsItem">
                    <span class="propsLable">Price includes</span
                    ><span class="propsCell"
                      >{{
                        orderData.stayAudltNum + orderData.stayChildNum
                      }}
                      people check-in</span
                    >
                  </li>
                  <li class="propsItem" v-if="item.bedTypeNameEn && item.bedTypeNameEn != 'null'">
                    <span class="propsLable">Bed Type</span
                    ><span class="propsCell">{{ item.bedTypeNameEn }}</span>
                  </li>
                  <li class="propsItem">
                    <span class="propsLable">Breakfast</span
                    ><span class="propsCell">{{
                      item.hasBreakfast ? "with breakfast" : "No breakfast"
                    }}</span>
                  </li>
                  <li class="propsItem">
                    <span class="propsLable">Window</span
                    ><span class="propsCell" v-if="item.windowType == 1"
                      >Windowless</span
                    >
                    <span class="propsCell" v-if="item.windowType == 2"
                      >Small window</span
                    >
                    <span class="propsCell" v-if="item.windowType == 3"
                      >Large window</span
                    >
                    <span class="propsCell" v-if="item.windowType == 4"
                      >French window</span
                    >
                  </li>
                </ul>
              </template>
            </div>

            <div class="price-list">
              <div class="price-list-title">Cost details</div>
              <div class="price-list-content">
                <div class="price-info-title mb-12">
                  <div class="color-black">Online payment</div>
                  <div class="text-right">
                    <div class="color-blue">HKD {{ orderData.finalPrice }}</div>
                  </div>
                </div>
                <div class="po-relative">
                  <div
                    class="mt-8"
                    v-if="
                      orderData.orderRoomDiscounts &&
                      orderData.orderRoomDiscounts.length != 0
                    "
                  >
                    <template
                      v-for="(item, index) in orderData.orderRoomDiscounts[0]
                        .details"
                    >
                    <div :key="index" style="display: flex;justify-content: space-between;margin-bottom:4px">

                      <div class="price-item color-dark-gray" :key="index">
                        <div>{{ item.date }}</div>
                      </div>
                      <div
                        class="price-item no-shrink color-dark-gray"
                        :key="index"
                      >
                        <div></div>
                        <div class="ml-4">HKD{{ item.originalPrice }}</div>
                      </div>
                    </div>
                 
                    </template>
                  </div>
                  <!-- <div class="flex-space-between mt-8">
                    <div class="price-item color-dark-gray">
                      <div>04-02</div>
                      <div class="ml-4">无早餐（离店日）</div>
                    </div>
                    <div class="price-item no-shrink color-dark-gray">
                      <div class="ml-4"></div>
                    </div>
                  </div>
                  <div class="flex-space-between mt-8">
                    <div class="price-item color-dark-gray">
                      <div>限时折扣</div>
                    </div>
                    <div class="price-item no-shrink color-dark-gray">
                      <div class="ml-4 color-oragne">-¥1,243</div>
                    </div>
                  </div> -->
                </div>
              </div>

              <div class="total-info color-dark-gray">
                <div class="total-info-title color-black">
                  Total payable amount
                </div>
                <div>
                  <span class="total-price-type color-black"></span
                  ><span class="total-price color-blue"
                    >HKD {{ orderData.finalPrice }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer :background="'#f8fafd'" />
    <loading :show="loadingShow" />
  </div>
</template>

<script>
import { hotelOrderDetail, hotelPay } from "@/api/index";
import { getDifferTime } from "@/utils/pub";
import { redirectToCheckout, loadAirwallex } from "airwallex-payment-elements";

export default {
  data() {
    return {
      loadingShow: false,
      orderData: {},
    };
  },
  created() {
    loadAirwallex({
      env: "prod",
      origin: window.location.origin,
    });
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    rangeSeparator(time) {
      if (time.length != 0) {
        return (
          getDifferTime(time[0], time[1]) +
          (getDifferTime(time[0], time[1]) > 1
            ? " nights"
            : " night")
        );
      }
    },
    payFun(item) {
      let data = {
        openId: "",
        orderId: this.orderData.id,
        payType: "h5", //写死
      };
      hotelPay(data).then((res) => {
        if (res.code == 200) {
          if (res.code == 200) {
            let token = sessionStorage.getItem("token").split("Bearer ");
            redirectToCheckout({
              env: "prod",
              mode: "payment",
              intent_id: res.data.h5PayParam.intent_id,
              client_secret: res.data.h5PayParam.client_secret,
              currency: res.data.h5PayParam.currency,
              methods: ["card", "wechatpay", "fps", "alipaycn"],
              theme: {
                // Must provide theme to display the checkout page properly
                fonts: [
                  // Customizes the font for the payment elements
                  {
                    src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
                    family: "AxLLCircular",
                    weight: 400,
                  },
                ],
              },
              successUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/success?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
              failUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/failure?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
              cancelUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/cancel?token=${token[1]}&orderId=${res.data.orderId}&env=web`,
            });
          }
        }
      });
    },
    getTodayAndTomorrowDate() {
      const now = new Date();
      let todayDate = this.formatDate(now);
      let tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24小时*60分钟*60秒*1000毫秒
      let tomorrowDate = this.formatDate(tomorrow);
      return [todayDate, tomorrowDate];
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    ReserveClick() {
      console.log(this.getTodayAndTomorrowDate());
      let data = {
        roomNum: this.orderData.roomNum,
        personNum: 1,
        childnNum: 0,
        hotelId: this.orderData.hotelId,
      };
      let path = `/hotel-detail?startDate=${
        this.getTodayAndTomorrowDate()[0]
      }&endDate=${this.getTodayAndTomorrowDate()[1]}&roomNum=${
        data.roomNum
      }&personNum=${data.personNum}&placeProvince=&childnNum=${
        data.childnNum
      }&hotelId=${this.orderData.hotelId}`;
      this.$router.push(path);
    },
    getInfo() {
      this.loadingShow = true;
      hotelOrderDetail({ id: sessionStorage.getItem('order') }).then((res) => {
        this.loadingShow = false;
        this.orderData = res.data;
      }).catch(err => {
        this.loadingShow = false;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.order-detail {
  .content {
    min-height: 90vh;
    padding-bottom: 32px;

    .panel {
      width: 1160px;
      margin: 0 auto;
      padding-top: 12px;

      .g-grid {
        margin-top: 12px;
        display: flex;
        justify-content: space-between;
        .g-grid-left {
          width: 67%;
          padding: 10px;
          margin-bottom: 20px;
          background: #f4f9ff;
        }

        .g-grid-right {
          width: calc(100% - 67% - 12px);
          padding: 10px;
          margin-bottom: 20px;
          background: #f4f9ff;
        }

        .m-order-status,
        .m-roomInfo,
        .m-concatInfo,
        .m-hotelInfo,
        .m-roomProps {
          margin-bottom: 10px;
          padding: 20px;
          background: #fff;
        }

        .m-order-status {
          .m-orderProcessStatus {
            font-size: 32px;
            display: flex;
            align-items: center;

            span {
              display: block;
              font-weight: 700;
            }

            i {
              font-size: 36px;
              margin-right: 12px;
            }
          }

          .warning {
            color: #ff9500;
          }

          .m-orderStatus_content {
            display: flex;
            align-items: center;
            margin-top: 18px;
            span {
              display: inline-block;
              font-size: 14px;
              padding-bottom: 15px;
            }

            .m-order-numberText {
              color: #999;
              display: table-cell;
              padding-right: 20px;
            }
          }

          .m-orderStatus-btn {
            display: flex;
            justify-content: flex-end;
            margin-top: 12px;
          }
        }

        .m-roomInfo {
          .m-checkInOut {
            display: flex;
            flex: 1;
            justify-content: space-between;

            .m-checkInOut_time {
              .timeLable {
                font-size: 12px;
                color: #999;
                margin-bottom: 6px;
              }

              .time {
                font-size: 14px;
                font-weight: 700;
              }
            }

            .m-checkInOut_qty {
              flex: 1;
              text-align: center;
              padding-top: 10px;
            }
          }
        }

        .m-concatInfo_content {
          padding-bottom: 6px;
          .g-flex {
            border: none !important;
          }
        }

        .g-flex {
          display: flex;
          padding: 16px 0;
          border-top: 1px solid #e4e4e4;
          font-size: 14px;
          line-height: 24px;

          &:first-child {
            border: none;
          }

          &:last-child {
            padding-bottom: 0;
          }

          .roomLable {
            width: 132px;
            color: #999;
          }

          .m-roomInfo_detail .g-flex .g-flex_col1 {
            position: relative;
            overflow: hidden;
          }
        }

        .m-module-normal_content {
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
        }

        .m-roomProps_title {
          padding-top: 24px;
          margin-top: -20px;
          border-top: 1px solid #e4e4e4;
          font-weight: 600;
        }

        .m-hotelInfo {
          display: flex;
          justify-content: space-between;
          margin: 0;
          .m-hotelInfo-photo {
            width: 107px;
            height: 107px;
            display: inline-flex;
            opacity: 1;
            transition: all 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
            justify-content: center;
            align-items: center;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: 50% 50%;
            }
          }

          .m-hotelInfo-desc {
            width: calc(100% - 107px - 12px);
            .hotelName {
              font-size: 16px;
              font-weight: 600;
              cursor: pointer;
            }

            .m-hotelInfo-address {
              color: #999;
              padding-top: 12px;
              line-height: 18px;
              font-size: 11px;
            }
          }
        }

        .m-roomProps {
          .propsList {
            margin-top: 10px;
            .propsItem {
              display: flex;
              margin-bottom: 8px;
              position: relative;
              font-size: 14px;

              .propsLable {
                color: #8592a6;
                font-weight: 300;
                line-height: 20px;
              }

              .propsCell {
                text-align: right;
                flex: 1;
                display: inline-block;
                padding-left: 24px;
              }
            }
          }
        }

        .price-list {
          padding: 24px 20px;
          background: #fff;

          .price-list-title {
            font-weight: 600;
            font-size: 16px;
            color: #333;
          }

          .price-list-content {
            padding: 24px 0;

            .price-info-title {
              font-size: 14px;
              font-weight: 600;
              display: flex;
              justify-content: space-between;
            }

            .po-relative {
              position: relative;

              .price-item {
                display: flex;
                font-size: 14px;
                line-height: 20px;

                > div {
                  font-weight: 300;
                }
              }
            }
          }

          .total-info {
            padding-top: 24px;
            border-top: 1px dashed rgba(0, 0, 0, 0.1);
            display: flex;
            justify-content: space-between;
            align-items: baseline;

            .total-info-title {
              font-size: 14px;
              font-weight: 600;
              margin-right: 24px;
              flex-shrink: 0;
            }

            .total-price {
              font-weight: 600;
              font-size: 24px;
              margin-left: 4px;
              color: #0f46a3;
            }
          }
        }
      }
    }
  }
}
</style>