<template>
    <div class="footer" :style="{background:background}">
        <p class="recommendations"><a href="https://www.viator.com/en-HK/tours/Hong-Kong-SAR/Hong-Kong-Street-Food-Feasting-Eat-with-a-Local/d35959-30791P167?pid=P00210510&mcid=42383&medium=link&medium_version=selector">Other recommendations in HongKong</a></p>

        <h6>TIANTIANZHU</h6>
        <p>sales@tiantianhotel.com</p>
        <p>©2024 All rights reserved by Tiantianzhu</p>
    </div>
</template>

<script>
export default {
    props:['background']
}
</script>

<style lang="scss" scoped>
.footer{
    height: 218px;
    background: #fff;
    text-align: center;
    color: #043373;


    h6{
        font-size: 22px;
        padding: 0 0px 22px;
        
    }

    p:nth-child(2){
        font-size: 15px;
        font-weight: 200;
        padding-bottom: 48px;
    }


    .recommendations{
        padding-top: 50px;
        a{
            color: #043373;
            text-decoration: underline;
        }
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 28px;
        
    }


    p:last-child{
        font-size: 14px;
        padding-bottom: 31px;

    }
}
</style>