import axios from "axios";
import store from "@/store";
import Cookies from 'js-cookie'
import Vue from "vue";
import router from "@/router";
import { Message } from "element-ui";


// create an axios instance
const service = axios.create({

  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 50000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // do something before request is sent
    // const lan = Cookies.get("language") ? (Cookies.get("language") == 'zh' ? '' : Cookies.get("language")) : ''
    config.headers['lan'] = 'en'
    const token = sessionStorage.getItem('token') || ''
    if (token) {
      config.headers["Authorization"] = token;
    }
    // config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
    // NProgress.start() 
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);


service.interceptors.response.use(
  (response) => {
    // NProgress.done()
    console.log(response);
    const res = response.data;
    // if the custom code is not 200, it is judged as an error.
    if (res.code !== 200 && res.code !== undefined) {
      if (res.code == 403) {
        // this.$router.push('/login') 
      }

      return res
    } else if (res.code == undefined) {
      return res;
    } else {
      return res;
    }
  },
  (error) => {
    if (error.response.status == 403) {
      router.push('/login')
    } else if(error.response.status == 401){
      Message.error('Account password error!')
    }else{
      Message.error('Network error, please refresh and try again!')
    }
    return Promise.reject(error);
  }
);


export default service;
