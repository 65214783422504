<template>
  <div class="hotel-detail">
    <Header />
    <div class="content">
      <div class="top">
        <div><search-hotel /></div>
      </div>
      <div class="panle">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">Hotel</el-breadcrumb-item>
          <el-breadcrumb-item
            >{{ list.enName }} <span class="f12">({{ list.name }})</span>
          </el-breadcrumb-item>
        </el-breadcrumb>

        <div class="detail-info">
          <div class="info-left">
            <h2>
              {{ list.enName }} <span class="f12">({{ list.name }})</span>
            </h2>
            <div class="addree">
              <i class="el-icon-location-outline"></i>
              <span>
                {{ list.placeCountry }} {{ list.placeProvince }}
                {{ list.placeCity }} {{ list.placeInfoEn }}</span
              >
              <span class="map-btn" @click="dialogVisible = true">Map</span>
            </div>
            <div class="addree desc" v-if="list.hotelDescEn">
              <i class="el-icon-office-building"></i>
              <span class="desc-text">{{ list.hotelDescEn }} </span>
              <span class="map-btn">More</span>
            </div>
            <div class="detail-headalbum_box">
              <div class="detail-headalbum_bigpic" @click="seeHotelDetail">
                <img
                  v-if="list.faceImageUrl"
                  :src="list.faceImageUrl[0]"
                  alt=""
                />
              </div>
              <!-- :style="
              list.hotelInfoImagesUrl.length <= 6 ? 'width: 380px;' : ''
            " -->
              <div
                class="detail-headalbum_childbox"
                v-if="list.hotelInfoImagesUrl"
              >
                <img
                  @click="seeHotelDetail"
                  v-for="(item, index) in list.hotelInfoImagesUrl.length > 6
                    ? list.hotelInfoImagesUrl.slice(0, 6)
                    : list.hotelInfoImagesUrl"
                  :key="index"
                  class="detail-headalbum_item"
                  :src="item"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="info-right">
            <div class="price-box" v-if="hotelPriceData.bestFinalPrice">
              <div class="reduce_price" v-if="hotelPriceData.bestDiscount != 0">
                <span class="reduce_price_span"
                  >Reduced by {{ hotelPriceData.bestDiscount }}</span
                >
              </div>
              <span
                class="delete-price"
                v-if="
                  hotelPriceData.bestOriginalPrice !=
                  hotelPriceData.bestFinalPrice
                "
                >HKD{{ hotelPriceData.bestOriginalPrice }}</span
              >
              <span class="price_price">
                <!-- <span class="price-qi">Starting from</span> -->
                HKD{{ hotelPriceData.bestFinalPrice }}
              </span>
              <div class="price-select" @click="goAnchor('#vital1')">
                Select room
              </div>
            </div>

            <div
              class="info-right-map"
              :style="!hotelPriceData.bestFinalPrice ? 'height:100%' : ''"
            >
              <div class="comment" v-if="commentData.length != 0">
                <div class="comment-left">
                  <p class="detail-headreview_score">
                    <span class="detail-headreview_score_box"
                      ><b class="detail-headreview_score_value">{{ score }}</b
                      ><span class="detail-headreview_score_fen">
                        Points</span
                      ></span
                    ><span class="detail-headreview_score_text">Very Good</span>
                  </p>

                  <p
                    class="detail-headreview_all"
                    @click="commentDialogVisible = true"
                  >
                    Show All {{ commentData.length }} Reviews
                  </p>
                  <!-- <p class="detail-headreview_quality">交通便利、环境优雅</p> -->
                </div>
                <div class="comment-right">
                  <div class="detail-headreview_bubble"></div>
                  <div class="detail-headreview_content">
                    {{ commentData[0].contentEn }}
                  </div>
                </div>
              </div>
              <div
                class="info-right--map"
                :style="commentData.length == 0 ? 'height:100%' : ''"
              >
                <baidu-map
                  class="map"
                  :center="{ lng: list.longitude, lat: list.latitude }"
                  :zoom="16"
                  :scroll-wheel-zoom="true"
                >
                  <bm-marker
                    :position="{ lng: list.longitude, lat: list.latitude }"
                    :dragging="true"
                  >
                    <bm-label
                      :content="list.name"
                      :labelStyle="{
                        borderColor: '#0f46a3',
                        color: '#0f46a3',
                        fontSize: '10px',
                      }"
                      :offset="{ width: -35, height: 30 }"
                    />
                  </bm-marker>
                </baidu-map>
              </div>
            </div>
          </div>
        </div>

        <div class="detail-room-list">
          <div class="search">
            <ul class="search-box">
              <li class="li li-item-calenda">
                <div class="wave-group">
                  <el-date-picker
                    class="time show-hightlight input"
                    :class="{ active: dateShow }"
                    v-model="query.time"
                    :picker-options="pickerOptions"
                    type="daterange"
                    :range-separator="rangeSeparator()"
                    start-placeholder="Start Date"
                    end-placeholder="End Date"
                    :value-format="'yyyy-MM-dd'"
                    @focus="dateFocus"
                    @blur="dateBlur"
                    popper-class="popper"
                  >
                  </el-date-picker>
                  <span style="top: 5px; left: -5px" class="bar"></span>
                </div>
              </li>
              <li class="li li-item-guests">
                <div
                  class="li-item wave-group"
                  @click.stop="inputFocus('guests')"
                >
                  <div
                    class="time show-hightlight input"
                    @click.stop="inputFocus('guests')"
                    :class="{ active: guestsShow }"
                  >
                    {{
                      query.roomNum > 1
                        ? query.roomNum + " Rooms"
                        : query.roomNum + " Room"
                    }}, {{ query.personNum }} Adults,
                    {{ query.childnNum }} Children
                  </div>
                  <span class="bar"></span>
                </div>
                <div @click.stop="inputFocus('guests')">
                  <i
                    :style="guestsShow ? 'transform: rotate(-180deg);' : ''"
                    class="el-icon-arrow-down"
                  ></i>
                </div>
                <div class="guests-box" v-if="guestsShow">
                  <ul>
                    <li>
                      <span>Room</span>
                      <el-input-number
                        size="small"
                        v-model="query.roomNum"
                      ></el-input-number>
                    </li>
                    <li>
                      <span
                        >Adults <br /><span class="tips"
                          >(Over 18 years old)</span
                        ></span
                      >
                      <el-input-number
                        size="small"
                        v-model="query.personNum"
                      ></el-input-number>
                    </li>
                    <li>
                      <span
                        >Children <br /><span class="tips"
                          >(0-17 years old)</span
                        ></span
                      >
                      <el-input-number
                        size="small"
                        v-model="query.childnNum"
                      ></el-input-number>
                    </li>
                  </ul>
                  <div class="btn">
                    <el-link type="primary" @click="guestsClick"
                      >Confirm</el-link
                    >
                  </div>
                </div>
              </li>
              <li class="li-dy"></li>
            </ul>
          </div>

          <div class="roomlist" id="vital1">
            <template v-if="roomData.length != 0">
              <div
                class="roomcard"
                v-for="(item, index) in roomData"
                :key="index"
              >
                <div class="roompanel">
                  <img
                    v-if="item.imageUrls"
                    style="cursor: pointer"
                    @click="seeRoom(item)"
                    :src="item.imageUrls[0]"
                    alt=""
                  />
                  <div
                    class="roomname"
                    @click="seeRoom(item)"
                    style="cursor: pointer"
                  >
                    {{ item.nameEn }}({{ item.nameCn }})
                  </div>
                  <div
                    class="roomdesc"
                    @click="seeRoom(item)"
                    style="cursor: pointer"
                  >
                    <span class="roompanel-facility-ib">{{
                      item.bedTypeName
                    }}</span>
                    <span class="roompanel-grey" v-if="item.size"> | </span>
                    <span class="roompanel-facility-ib">{{ item.size }}</span>
                    <span class="roompanel-grey" v-if="item.lastRoomNum">
                      |
                    </span>
                    <span class="roompanel-facility-ib"
                      >{{ item.lastRoomNum }} remaining rooms</span
                    >
                    <span class="roompanel-grey" v-if="item.windowType">
                      |
                    </span>
                    <span class="roompanel-facility-ib">{{
                      windowType(item.windowType)
                    }}</span>
                  </div>
                  <div class="seeroom" @click="seeRoom(item)">
                    Room Information
                  </div>
                </div>
                <div class="saleroomlist">
                  <div class="salecardlist-rooms">
                    <div
                      class="ubt-salecard salecard"
                      v-for="(subItem, subIndex) in item.ratePlanVOS"
                      :key="subIndex"
                    >
                      <div class="salecard-flex">
                        <div class="salecard-left">
                          <div class="adult">
                            <i
                              v-for="(
                                userItem, userIndex
                              ) in item.allowPersonNum"
                              :key="userIndex"
                              class="el-icon-user-solid"
                            ></i>
                          </div>
                          <div class="bedfacility">
                            <i
                              class="icon iconfont icon-zaocan"
                              style="font-size: 18px"
                            ></i>
                            {{ breakfastType(subItem.breakfastType) }}
                          </div>
                          <div class="policy">
                            <i
                              style="font-size: 18px"
                              class="icon iconfont"
                              :class="
                                subItem.refundable == 0
                                  ? 'icon-buketui'
                                  : 'icon-ketui'
                              "
                            ></i>
                            {{
                              subItem.refundable == 0
                                ? "Non refundable"
                                : "Refundable"
                            }}
                          </div>
                        </div>
                        <div class="salecard-right">
                          <div class="salecard-price-panel">
                            <div class="price">
                              <span
                                class="price-delete"
                                v-if="
                                  subItem.originalPrice !=
                                  subItem.minPriceAfterTicket
                                "
                                >HKD{{ subItem.originalPrice }}</span
                              >
                              <span class="price-display"
                                >HKD{{ subItem.minPriceAfterTicket }}</span
                              >
                            </div>
                            <div class="discount-tag">
                              <span class="tag" v-if="subItem.allDiscount != 0"
                                >Discount {{ subItem.allDiscount }}</span
                              >
                            </div>
                          </div>
                          <div class="salecard-price-button">
                            <div class="book-box">
                              <span @click="goReserve(item, subItem, subIndex)"
                                >Reserve</span
                              >
                            </div>
                            <p class="paytype">Online payment</p>
                            <!-- <p class="roomhold">剩{{item.lastRoomNum}}间</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div style="height: 500px; background: #fff">
                <el-empty
                  :image-size="250"
                  description="There are currently no rooms available"
                ></el-empty>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <Footer />

    <roomDetail
      v-if="roomDetailShow"
      :roomDetails="roomDetails"
      @close="close"
    />

    <loading :show="loadingShow" />

    <!-- 地图弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="650px"
      :before-close="handleClose"
    >
      <div>
        <baidu-map
          class="bd-map"
          :center="{ lng: list.longitude, lat: list.latitude }"
          :zoom="18"
          :scroll-wheel-zoom="true"
        >
          <bm-marker
            :position="{ lng: list.longitude, lat: list.latitude }"
            :dragging="true"
          >
            <bm-label
              :content="list.name"
              :labelStyle="{
                borderColor: '#0f46a3',
                color: '#0f46a3',
                fontSize: '10px',
              }"
              :offset="{ width: -35, height: 30 }"
            />
          </bm-marker>
        </baidu-map>
      </div>
    </el-dialog>

    <!-- 酒店详情弹框 -->
    <el-dialog
      :title="currentHotelDetail.enName"
      :visible.sync="detailDialogVisible"
      width="1200px"
      style="top: -10px"
    >
      <div class="hotel-detail-box">
        <div class="hotel-detail-box--left">
          <div class="article-box">
            <div class="m-arrow">
              <i class="el-icon-arrow-left" @click="prev"></i>
            </div>
            <div class="big-img" v-if="currentHotelDetail.hotelInfoImagesUrl">
              <img
                :src="
                  imgUrlIndex == ''
                    ? currentHotelDetail.hotelInfoImagesUrl[0]
                    : currentHotelDetail.hotelInfoImagesUrl[imgUrlIndex]
                "
                alt=""
              />
              <div class="m-imgInfo">
                {{ imgUrlIndex == "" ? 1 : imgUrlIndex + 1 }}/{{
                  currentHotelDetail.hotelInfoImagesUrl.length
                }}
              </div>
            </div>
            <div class="m-arrow">
              <i class="el-icon-arrow-right" @click="next"></i>
            </div>
          </div>

          <div class="m-album-nav">
            <ul class="m-album-nav-img">
              <li
                v-for="(item, index) in currentHotelDetail.hotelInfoImagesUrl"
                :key="index"
                @click="selImg(item, index)"
                :class="{ 'li-active': index == imgUrlIndex }"
              >
                <img :src="item" alt="" />
              </li>
            </ul>
          </div>
        </div>

        <div class="hotel-detail-box--right">
          <h3>Contact Information</h3>
          <!-- <p><span>Email：</span> {{ currentHotelDetail.email }}</p> -->
          <p><span>Telephone：</span> {{ currentHotelDetail.contactPhone }}</p>
          <div
            class="m-room-facility"
            v-if="
              currentHotelDetail.facilities &&
              currentHotelDetail.facilities.findIndex((v) => v.available) != -1
            "
          >
            <h3>All facilities</h3>
            <ul>
              <template v-for="(item, index) in currentHotelDetail.facilities">
                <li :key="index" v-if="item.available">
                  <div class="icon-with-text" :key="index">
                    <i class="icon iconfont icon-tick"></i>
                    <span>{{ item.name }}</span>
                  </div>
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 评论弹出框 -->
    <el-dialog
      title=""
      :visible.sync="commentDialogVisible"
      width="900px"
      :before-close="handleClose"
    >
      <div class="comment-box">
        <div
          class="comment-item"
          v-for="(item, index) in commentData"
          :key="index"
        >
          <div class="comment-item-left">
            <div class="user">
              <img
                style="width: 32px; height: 32px"
                src="../../assets/images/Group 851@2x.png"
                alt=""
              />
              <!-- <el-avatar
                size="50px"
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
              ></el-avatar> -->
              <span>{{ item.submitter }}</span>
            </div>
            <p v-if="list.enName">
              <i style="font-size: 18px" class="el-icon-school"></i>
              {{ list.enName }}
            </p>
           
            <p v-if="item.roomName">
              <i class="icon iconfont icon-chuang"></i> {{ item.roomName }}
            </p>

             <p v-if="item.date">
              <i style="font-size: 18px" class="el-icon-time"></i>
              {{ item.date }}
            </p>
          </div>

          <div class="comment-item-right">
            <div class="score-box">
              <span>{{ item.score }}</span> / 5
            </div>
            <p>{{ item.contentEn }}</p>
            <div class="imgs" v-if="item.image != ''">
              <img
                v-for="(subItem, subIndex) in item.image"
                :key="subIndex"
                :src="imageUrl(subItem)"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import roomDetail from "./components/roomDetail.vue";
import {
  hotelHotelDetail,
  hotelRoomList,
  hoteWeblRoomList,
  hotelRoomType,
  commentList,
  fileGet,
} from "@/api/index";
// import Loading from '@/components/Loading'
import { getDifferTime } from "@/utils/pub";
export default {
  components: {
    roomDetail,
    // Loading
  },
  data() {
    return {
      center: { lng: 0, lat: 0 },
      zoom: 15,
      dialogVisible: false,
      roomDetailShow: false,
      guestsShow: false,
      dateShow: false,
      loadingShow: false,
      list: {},
      roomData: [],
      roomDetails: {},
      query: {
        time: null,
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
      },
      hotelPriceData: {},
      detailDialogVisible: false,
      currentHotelDetail: {},
      imgUrlIndex: 0,
      commentData: [],
      score: null,
      commentDialogVisible: false,
      imageData: [],
    };
  },
  watch: {
    guestsShow(newval, oldval) {
      console.log(newval, oldval);
      if (newval == false) {
        this.query.startDate = this.query.time[0];
        this.query.endDate = this.query.time[1];
        let data = {
          clockRentHourSelect: [],
          rentType: 1,
          type: "",
          ...this.query,
        };
        this.getRoomList(data);
      }
    },
  },
  mounted() {
    this.getDetail();
    this.getRating();
    this.query = JSON.parse(sessionStorage.getItem("hotelSearchData"));
    this.query.time = [
      JSON.parse(sessionStorage.getItem("hotelSearchData")).startDate,
      JSON.parse(sessionStorage.getItem("hotelSearchData")).endDate,
    ];
  },
  computed: {},
  methods: {
    imageUrl(id) {
      if (id) {
        return this.imageData.find((v) => v.id == id).tempUrl;
      }
      return "";
      // console.log(this.imageData.find(v => v.id == id).tempUrl);
    },
    getImages(ids) {
      console.log(ids, "ids");
      fileGet({ ids: ids }).then((res) => {
        this.imageData = res.data;
      });
    },
    formatNumber(num) {
      // 检查是否有小数点
      if (num.toString().includes(".")) {
        // 将字符串转换为数字，并格式化保留一位小数
        return Number.parseFloat(num).toFixed(1);
      }
      // 如果没有小数点，则直接返回原数字
      return num;
    },
    getRating() {
      let data = {
        hotelId: JSON.parse(sessionStorage.getItem("hotelSearchData")).hotelId,
        page: {
          page: 1,
          size: 100,
        },
      };
      commentList(data).then((res) => {
        let ids = [];
        let num = 0;
        res.data.forEach((v) => {
          if (v.image) {
            if (v.image.indexOf(",") > -1) {
              v.image = v.image.split(",");
              ids.push(...v.image);
            } else {
              v.image = [v.image];
              ids.push(...v.image);
            }
          }
          num = v.score + num;
        });

        this.commentData = res.data;
        this.score = this.formatNumber(num / this.commentData.length);
        console.log(ids, res.data, "12");
        // if (res.data.image != null) {
        //   if (res.data.image.indexOf(",") > -1) {
        //     ids = res.data.image.split(",");
        //   } else {
        //     ids = [res.data.image];
        //   }
        this.getImages(ids);
        // }
      });
    },
    next() {
      if (
        this.imgUrlIndex <
        this.currentHotelDetail.hotelInfoImagesUrl.length - 1
      ) {
        this.imgUrlIndex++;
      }
    },
    prev() {
      if (this.imgUrlIndex > 0) {
        this.imgUrlIndex--;
      }
    },
    selImg(item, index) {
      this.imgUrlIndex = index;
    },
    seeHotelDetail() {
      // this.loadingShow = true;
      // hotelDetail({ id: this.list.id }).then((res) => {

      this.detailDialogVisible = true;
      //   this.loadingShow = false;
      // });
    },
    rangeSeparator() {
      if (this.query.time && this.query.time.length != 0) {
        return (
          getDifferTime(this.query.time[0], this.query.time[1]) +
          (getDifferTime(this.query.time[0], this.query.time[1]) > 1
            ? " nights"
            : " night")
        );
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.commentDialogVisible = false;
    },
    handler({ BMap, map }) {
      this.center.lng = 114.172715;
      this.center.lat = 22.313931;
      this.zoom = 0;
    },
    goAnchor(selector) {
      /*参数selector是id选择器（#anchor14）*/
      document.querySelector(selector).scrollIntoView({
        behavior: "smooth",
      });
    },
    guestsClick() {
      this.guestsShow = false;
    },
    dateFocus(e) {
      this.dateShow = true;
    },
    dateBlur(e) {
      this.dateShow = false;
      this.query.startDate = this.query.time[0];
      this.query.endDate = this.query.time[1];
      let data = {
        clockRentHourSelect: [],
        rentType: 1,
        type: "",
        ...this.query,
      };
      this.getRoomList(data);
    },
    inputFocus(type) {
      if (type == "guests") {
        this.guestsShow = !this.guestsShow;
      }
    },
    breakfastType(type) {
      // 1含双早 2含单早 3不含早餐
      let res = "";
      if (type == 1) {
        res = "with breakfast";
      } else if (type == 0) {
        res = "Excluding breakfast";
      }
      return res;
    },
    windowType(type) {
      //1无窗 2小窗 3大窗 4落地窗
      let res = "";
      if (type === 1) {
        res = "Windowless";
      } else if (type === 2) {
        res = "Small window";
      } else if (type === 3) {
        res = "Large window";
      } else if (type === 3) {
        res = "French window";
      }
      return res;
    },
    seeRoom(item) {
      this.loadingShow = true;
      hotelRoomType({ id: item.id })
        .then((res) => {
          this.roomDetails = res.data;
          this.loadingShow = false;
          this.roomDetailShow = true;
        })
        .catch((err) => {
          this.loadingShow = false;
        });
    },
    getDetail() {
      this.loadingShow = true;
      hotelHotelDetail({
        id: JSON.parse(sessionStorage.getItem("hotelSearchData")).hotelId,
      })
        .then((res) => {
          this.loadingShow = false;
          if (res.code == 200) {
            this.list = res.data;
            this.currentHotelDetail = JSON.parse(JSON.stringify(this.list));
            this.currentHotelDetail.hotelInfoImagesUrl.unshift(
              ...this.list.faceImageUrl
            );
            let data = {
              clockRentHourSelect: [],
              rentType: 1,
              type: "",
              ...JSON.parse(sessionStorage.getItem("hotelSearchData")),
            };
            this.getRoomList(data);
          }
        })
        .catch((err) => {
          this.loadingShow = false;
        });
    },
    getRoomList(data) {
      this.loadingShow = true;
      hotelRoomList(data)
        .then((res) => {
          this.roomData = res.data;
          this.loadingShow = false;
        })
        .catch((err) => {
          this.loadingShow = false;
        });
      hoteWeblRoomList(data)
        .then((res) => {
          this.hotelPriceData = res.data;
        })
        .catch((err) => {
          this.loadingShow = false;
        });
    },
    close() {
      this.roomDetailShow = !this.roomDetailShow;
      this.goAnchor("#vital1");
    },
    goReserve(item, subItem, subIndex) {
      let data = {
        ...JSON.parse(sessionStorage.getItem("hotelSearchData")),
        rentType: 1,
        roomId: item.id,
        ratePlanIndex: subIndex,
      };
      let checkHotel = {
        ...item,
        breakfastType: subItem.breakfastType,
        refundable: subItem.refundable,
      };
      sessionStorage.setItem("hotelSearchData", JSON.stringify(data));
      localStorage.setItem("checkHotel", JSON.stringify(checkHotel));
      // ?startDate=${data.startDate}&endDate=${data.endDate}&roomNum=${data.roomNum}&personNum=${data.personNum}&childnNum=${data.childnNum}&hotelId=${data.hotelId}&roomId=${item.id}&ratePlanIndex=${subIndex}
      this.$router.push(`/hotel-book`);
    },
  },
};
</script>

<style lang="scss" scoped>
.bd-map {
  width: 600px;
  height: 600px;
}
.hotel-detail {
  .content {
    min-height: 90vh;
    background: #f5f7fa;
    padding-bottom: 32px;

    .top {
      display: flex;
      justify-content: center;
      padding: 16px 0;
      //background: #fff;

      > div {
        width: 1160px;
        margin: 0 auto;
      }
    }

    .panle {
      width: 1160px;
      margin: 0 auto;
      padding-top: 14px;

      .detail-info {
        background: #fff;
        padding: 16px 24px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;

        .info-left {
          max-width: 75%;
          h2 {
            font-size: 20px;
            color: #0f294d;
            display: inline;
            margin-right: 8px;
            margin-bottom: 8px;
            font-weight: 600;
          }

          .addree {
            margin-top: 8px;
            font-size: 14px;
            color: #455873;
            letter-spacing: 0;
            text-align: left;
            line-height: 18px;

            .map-btn {
              display: inline-block;
              font-size: 14px;
              color: #0f46a3;
              font-weight: 700;
              cursor: pointer;
              word-break: keep-all;
              margin-left: 4px;
              text-decoration: underline;
            }
          }

          .desc {
            display: flex;
            align-items: center;
            .desc-text {
              width: 70%;
              display: block;
              line-height: 18px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              flex: 0 1 auto;
            }
          }

          .detail-headalbum_box {
            margin-top: 8px;
            display: flex;
            .detail-headalbum_bigpic {
              width: 380px;
              height: 278px;
              cursor: pointer;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .detail-headalbum_childbox {
              display: flex;
              flex-wrap: wrap;
              align-content: space-between;
              width: 260px;
              img {
                width: 119px;
                height: 84px;
                margin-left: 8px;
                cursor: pointer;
              }
            }
          }
        }

        .info-right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 40%;

          .price-box {
            display: flex;
            //align-items: center;
            height: 32px;
            padding-top: 32px;
            position: relative;
            justify-content: flex-end;
          }

          .reduce_price {
            position: absolute;
            right: 109px;
            top: 2px;
          }

          .reduce_price_span {
            font-weight: 500;
            font-size: 14px;
            color: #fff;
            text-align: center;
            background: #f5594a;
            padding: 0 8px;
            border-radius: 2px 2px 0px 2px;
            margin-bottom: 8px;
            position: relative;
            line-height: 24px;
            height: 22px;

            &::after {
              content: "";
              position: absolute;
              top: 20px;
              right: 0;
              border-top: 6px solid #f5594a;
              border-left: 6px solid transparent;
            }
          }

          .delete-price {
            font-size: 14px;
            color: #455873;
            margin-right: 8px;
            text-decoration: line-through;
            padding-top: 16px;
          }

          .price_price {
            font-weight: 700;
            font-size: 24px;
            color: #0f46a3;
            display: flex;
            align-items: flex-end;
            //text-align: right;

            .price-qi {
              font-size: 14px;
              color: #455873;
              margin-left: 2px;
              position: relative;
              top: -2px;
            }
          }

          .price-select {
            height: 40px;
            padding: 0 12px;
            background: #0f46a3;
            border-radius: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            cursor: pointer;
            margin-left: 8px;
          }

          .info-right-map {
            width: 100%;
            min-height: 244px;
            //background: #0f294d;

            .comment {
              width: 98%;
              height: 94px;
              margin-top: 10px;
              display: flex;
              justify-content: space-between;

              .comment-left {
                .detail-headreview_score_box {
                  background-color: #4978ce;
                  padding: 3px 8px;
                  border-radius: 2px;
                  font-size: 22px;
                  line-height: 22px;
                  color: hsla(0, 0%, 100%, 0.6);
                  font-weight: 700;
                  cursor: pointer;
                  white-space: nowrap;
                  display: inline-block;
                }

                .detail-headreview_score_value {
                  color: #fff;
                  font-size: 20px;
                  margin-right: 2px;
                }

                .detail-headreview_score_fen {
                  font-size: 16px;
                  line-height: 22px;
                  color: #fff;
                  font-weight: 500;
                  opacity: 0.6;
                }

                .detail-headreview_score_text {
                  color: #4978ce;
                  font-size: 15px;
                  margin-left: 8px;
                  font-weight: 700;
                  position: relative;
                  top: 1px;
                }

                .detail-headreview_all {
                  margin-bottom: 4px;
                  font-size: 14px;
                  color: #287dfa;
                  font-weight: 700;
                  cursor: pointer;
                  white-space: nowrap;
                  margin: 8px 0;
                }

                .detail-headreview_quality {
                  margin-bottom: 4px;
                  font-size: 14px;
                  color: #06aebd;
                  font-weight: 400;
                  white-space: nowrap;
                }
              }

              .comment-right {
                height: 100%;
                //background: #f5f9ff;
                position: relative;
                width: 51%;

                .detail-headreview_bubble {
                  padding: 8px;
                  transform: rotate(45deg);
                  background-color: #f5f9ff;
                  position: absolute;
                  left: -4px;
                  top: 20px;
                }

                .detail-headreview_content {
                  background: #f5f9ff;
                  //height: 54px;
                  max-width: 198px;
                  margin-left: 20px;
                  //padding: 12px;
                  position: relative;
                  height: 73px; /* 设置容器的高度 */ /* 注意：高度需要根据实际情况调整 */
                  overflow: hidden; /* 隐藏超出部分的内容 */
                  text-overflow: ellipsis; /* 在文本末尾添加省略号 */
                  display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
                  -webkit-line-clamp: 4; /* 限制在一个块元素显示的文本的行数 */
                  -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
                  outline: 15px solid #f5f9ff;
                  //word-break: break-all;
                  line-height: 18px;
                  font-size: 14px;
                  color: #455873;
                  letter-spacing: 0;
                }
              }
            }

            .map {
              width: 100%;
              height: 100%;
            }

            .info-right--map {
              width: 100%;
              height: 57%;
            }
          }
        }
      }

      .detail-room-list {
        background: #fff;
        margin-top: 12px;

        .search {
          padding: 24px 24px 8px;
        }

        .search-box {
          width: calc(100% - 32px);
          display: flex;
          background: #f5f7fa;
          padding: 18px 16px;

          .li {
            height: 44px;
            background: #fff;
            cursor: pointer;
            &:first-child {
              width: 454px;
            }

            &:nth-child(2) {
              width: 244px;
              border-right: none;
            }

            &:last-child {
            }

            .li-item {
            }

            .label {
              font-size: 14px;
              color: #8592a6;
              line-height: 18px;
              user-select: none;
            }

            .show-hightlight {
              font-weight: 700;
              font-size: 16px;
              color: #0f294d !important;
              //line-height: 22px;
            }

            .nights {
              font-size: 14px;
              color: #0f294d;
              line-height: 18px;
            }
          }

          .li-dy {
            width: calc(100% - 454px - 275px);
            background: #fff;
          }

          .li-item-calenda {
            //display: flex;
            justify-content: space-between;
            align-items: center;
            border-right: 1px solid #dadfe6;
            padding-left: 10px;

            .time-tab {
              width: 100%;
              //height: 100%;
              display: flex;
              //flex-direction: column;
              justify-content: space-between;
            }

            .checkout {
              text-align: end;

              .input {
                padding-right: 0 !important;
                text-align: end !important;
              }
            }

            ::v-deep {
              .el-range-editor.el-input__inner {
                border: none;
                padding: 0;
                // height: 30px;
              }
              .el-date-editor {
                width: 100%;
              }
              .el-range-separator {
                width: 100px;
              }
              .el-range-input {
                width: 160px;
                background: transparent;
                font-weight: 700;
                font-size: 16px;
                color: #0f294d !important;
                &:nth-child(2) {
                  text-align: start;
                }
                text-align: end;
              }
            }
          }

          .li-item-guests {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-left: 16px;

            .guests-box {
              position: absolute;
              left: -1px;
              top: 69px;
              width: 100%;
              background: #fff;
              border: 1px solid #dadfe6;

              ul {
                padding-top: 12px;
              }

              li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 16px 12px;
                color: #0f294d;
                font-size: 16px;

                .tips {
                  display: block;
                  font-size: 12px;
                  line-height: 1;
                  color: #6b8299;
                  margin-top: 6px;
                }
              }

              .btn {
                text-align: end;
                padding: 16px;
              }
            }
          }

          .wave-group {
            position: relative;
          }

          .wave-group .input {
            font-size: 16px;
            padding: 10px 10px 10px 0;
            display: block;
            //width: 200px;
            border: none;
            background: transparent;
          }

          .wave-group .input:focus {
            outline: none;
          }

          .wave-group .bar {
            position: relative;
            display: block;
            // width: 200px;
          }

          .wave-group .bar:before,
          .wave-group .bar:after {
            content: "";
            height: 2px;
            width: 0;
            bottom: 0px;
            position: absolute;
            background: #5264ae;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
          }

          .wave-group .bar:before {
            left: 50%;
          }

          .wave-group .bar:after {
            right: 50%;
          }

          .wave-group .input:focus ~ .bar:before,
          .wave-group .input:focus ~ .bar:after {
            width: 50%;
          }

          .active ~ .bar:before,
          .active ~ .bar:after {
            width: 50%;
          }
        }

        .roomlist {
          border-top: 4px solid #f5f7fa;
          .roomcard {
            display: flex;
            margin-top: 4px;
            padding: 24px;
            border-top: 4px solid #f5f7fa;

            .roompanel {
              border-right: 1px solid #dadfe6;
              flex: auto;
              max-width: 204px;
              width: 204px;
              box-sizing: border-box;
              padding-right: 24px;
              img {
                width: 100px;
                height: 71px;
                position: relative;
                cursor: pointer;
              }

              .roomname {
                width: 180px;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #0f294d;
                padding: 6px 0;
              }

              .roomdesc {
                color: #0f294d;
                font-size: 14px;
                display: inline-block;
                padding-bottom: 1px;
                margin-bottom: 8px;
              }

              .roompanel-grey {
                color: #d1d5db;
              }

              .seeroom {
                padding-bottom: 12px;
                font-size: 12px;
                color: #0f46a3;
                line-height: 12px;
                text-align: left;
                font-weight: 600;
                cursor: pointer;
              }
            }

            .saleroomlist {
              width: calc(100% - 204px);
              .ubt-salecard {
                padding: 24px 0 24px 24px;
                border-bottom: 1px solid #dadfe6;

                &:last-child {
                  border: none;
                }
                .salecard-flex {
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  .salecard-left {
                    display: flex;
                    .adult {
                      width: 100px;

                      i {
                        color: #455873;
                      }
                    }
                    .bedfacility {
                      min-width: 165px;
                      color: #0f294d;
                      font-size: 14px;
                      margin-left: 32px;
                    }
                    .policy {
                      min-width: 119px;
                      color: #0f294d;
                      font-size: 14px;

                      .icon-buketui {
                        color: #d81e06;
                      }
                    }
                  }

                  .salecard-right {
                    display: flex;

                    .salecard-price-panel {
                      display: flex;
                      flex-direction: column;
                      align-items: flex-end;
                      margin-right: 16px;
                      .price {
                        &-delete {
                          font-size: 14px;
                          color: #455873;
                          text-align: right;
                          line-height: 18px;
                          text-decoration: line-through;
                          margin-right: 8px;
                        }

                        &-display {
                          line-height: 28px;
                          color: #0f46a3;
                          font-size: 24px;
                          display: inline-block;
                          font-weight: 600;
                        }
                      }

                      .discount-tag {
                        .tag {
                          padding: 2px 4px;
                          line-height: 1;
                          box-sizing: border-box;
                          background-color: rgba(255, 111, 0, 0.2);
                          font-size: 12px;
                          margin-bottom: 8px;
                          color: #ff6f00;
                          border-radius: 2px;
                          border: 1px solid rgba(255, 111, 0, 0.3);
                          display: inline-flex;
                          flex-direction: row-reverse;
                          flex-wrap: wrap;
                        }
                      }
                    }

                    .salecard-price-button {
                      .book-box {
                        span {
                          font-size: 16px;
                          line-height: 38px;
                          background-color: #ff9500;
                          text-align: center;
                          height: 38px;
                          justify-content: center;
                          align-items: center;
                          border-radius: 2px;
                          font-weight: 700;
                          padding: 0 24px;
                          box-sizing: border-box;
                          min-width: 75px;
                          display: inline-block;
                          cursor: pointer;
                          color: #fff;
                        }
                      }

                      .paytype {
                        text-align: center;
                        font-size: 14px;
                        font-weight: 600;
                        color: #455873;
                        line-height: 20px;
                        margin-top: 8px;
                        margin-bottom: 4px;
                      }

                      .roomhold {
                        text-align: center;
                        clear: both;
                        color: #ee3b28;
                        font-size: 14px;
                        font-weight: 400;
                        height: 18px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.hotel-detail-box {
  //min-height: 60vh;
  display: flex;
  justify-content: space-between;

  .hotel-detail-box--left {
    width: 744px;
  }

  .hotel-detail-box--right {
    width: 350px;
    // background: #6b8299;

    h3 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 24px;
    }

    p {
      font-size: 16px;
      color: #8592a6;
      margin-bottom: 18px;

      span {
        color: #333;
      }
    }
  }

  .article-box {
    display: flex;
    align-items: center;
    .m-arrow {
      font-size: 40px;
      cursor: pointer;
    }

    .m-center-box {
      height: 100%;
      margin: 0 24px;

      .m-title {
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: 38px;
      }
    }
  }

  .big-img {
    position: relative;
    width: 744px;
    height: 495px;
    //background: #fff;

    .m-imgInfo {
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      font-size: 12px;
      background-image: linear-gradient(
        180deg,
        transparent,
        rgba(15, 41, 77, 0.5)
      );
      text-align: end;
      padding: 12px;
    }

    img {
      position: absolute;
      //top: 50%;
      //transform: translateY(-50%);
      width: 100%;
      height: 495px;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .m-album-nav {
    width: 744px;
    .m-album-nav-img {
      display: flex;
      margin: 30px 0 0;
      overflow-x: auto;
      overflow-y: hidden;

      li {
        display: inline-block;
        width: 120px;
        height: 80px;
        border: 2px solid #fff;
        cursor: pointer;
        background-size: cover;
      }

      .li-active {
        border-color: #000;
      }

      li ~ li {
        margin-left: 4px;
      }

      img {
        width: 120px;
        height: 80px;
      }
    }
  }

  .m-room-facility {
    .m-facility {
      font-size: 18px;
      line-height: 24px;
      margin: 8px 0;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 12px;
        text-align: center;
        margin-bottom: 6px;

        i {
          margin-right: 6px;
        }
      }
    }

    .m-aside-content {
      .icon-with-text {
        font-size: 14px;
        width: 45%;
        display: inline-flex;
        word-break: break-word;
        margin: 8px 5% 8px 0;
        vertical-align: top;
        line-height: 22px;
      }
    }
  }
}

::v-deep {
  .BMap_cpyCtrl {
    display: none !important;
  }
}

.comment-box {
  height: 400px;
  overflow: auto;

  .comment-item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 24px 0;

    .comment-item-left {
      width: 232px;
      .user {
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          font-size: 16px;
          word-break: break-all;
          margin-left: 8px;
        }
      }

      p {
        font-size: 14px;
        color: #8592a6;
        margin-top: 10px;
      }
    }

    .comment-item-right {
      width: calc(100% - 260px);

      .score-box {
        font-weight: 700;
        margin-left: 10px;
        color: #acb4bf;

        span {
          display: inline-block;
          font-size: 28px;
          color: #366ab3;
        }
      }

      .imgs {
        img {
          width: 180px;
          height: 150px;
          margin-right: 12px;
        }
      }

      p {
        margin: 10px 0 16px;
        font-size: 16px;
        color: #455873;
        letter-spacing: 0;
        line-height: 26px;
        word-break: break-word;
      }
    }
  }
}

.comment-box::-webkit-scrollbar {
  display: none; /* 针对Webkit浏览器 */
}
</style>