import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/Home/index.vue'
import HotelList from '../views/Hotel/list.vue'
import HotelDetail from '../views/Hotel/detail.vue'
import HotelBook from '../views/Reserve/index.vue'
import Order from '../views/Order/index.vue'
import HotelOrderDetail from '../views/Order/detail.vue'
import Login from '../views/Login/index.vue'
import Mine from '../views/Mine/index.vue'
import Coupon from '../views/Coupon/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: "首页",
      // analytics: {
      //   pageviewTemplate(route) {
      //     return {
      //       title: "首页",
      //       page: route.path,
      //     };
      //   }
      // }
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/hotel-list',
    name: 'HotelList',
    component: HotelList
  },
  {
    path: '/hotel-detail',
    name: 'HotelDetail',
    component: HotelDetail,
    meta: {
      _blank: true // 需要添加上
    }
  },
  {
    path: '/hotel-book',
    name: 'HotelBook',
    component: HotelBook,
  },
  {
    path: '/mine',
    name: 'Mine',
    component: Mine
  },
  {
    path: '/coupon',
    name: 'Coupon',
    component: Coupon,
    meta: {
      title: "优惠券",
    }
    
  },
  {
    path: '/order',
    name: 'HotelOrderBook',
    component: Order,
  },
  {
    path: '/order-detail',
    name: 'HotelOrderDetail',
    component: HotelOrderDetail,
  },
  {
    path: '/channel-statistics',
    name: 'ChannelStatistics',
    component: () => import('../views/ChannelStatistics/index.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}


router.afterEach((to, from, next) => {
  // console.log(window.location.href,to,'11');
  window.gtag('config', 'G-3XPSPFPVE1', {
      page_title: to.meta.title,
      page_path: to.path,
      page_location: window.location.href
  })
})


export default router
