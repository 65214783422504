<template>
  <div class="order">
    <Header />
    <div class="content">
      <div class="panel">
        <MineTab :tabValue="1"/>

        <div class="order-list">
          <el-tabs
            v-model="editableTabsValue"
            type="card"
            @tab-click="tabClick"
          >
            <el-tab-pane
              :key="index"
              v-for="(item, index) in editableTabs"
              :label="item.title"
              :name="item.name"
            >
              <div class="list-box">
                <ul class="t_body" v-if="orderData.length != 0">
                  <li
                    class="item"
                    id="HotelInternational_Detail_31345677264"
                    v-for="(item, index) in orderData"
                    :key="index"
                  >
                    <h3>
                      <!-- <el-checkbox v-model="checked"></el-checkbox> -->
                      <span class="ilb mr20"
                        >orderId：<span class="card-order-id order-btn">{{
                          item.orderId
                        }}</span></span
                      ><span class="ilb mr20"
                        >Booking date：{{ item.orderTime }}</span
                      >
                    </h3>
                    <div class="order-items clearfix order-btn">
                      <div class="order-info clearfix">
                        <ul class="order-item">
                          <li class="order-item-title">
                            <span
                              style="
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                              "
                              >{{ item.hotelEnName }} <span style="display:inline-block" class="f12">({{
                                item.hotelName
                              }})</span> </span
                            >
                          </li>
                          <li class="order-item-txt">
                            <!-- <span>Kaigan 1-16-2</span> -->
                          </li>
                          <li class="order-item-txt">
                            <span
                              >Check-in date: {{ item.checkDate }} to
                              {{ item.outDate }}
                             {{ rangeSeparator([item.checkDate, item.outDate]) }}/{{
                                item.roomNum
                              }}{{item.roomNum == 1 ? ' room' : ' rooms'}}</span
                            >
                          </li>
                          <li class="order-item-txt">
                            <span>Occupant: {{ item.orderSubInfoList[0].guestFirstName }}/{{ item.orderSubInfoList[0].guestLastName }}</span>
                          </li>
                          <li class="order-item-txt">
                            <span>{{ item.orderSubInfoList[0].roomNameEn }}</span>
                          </li>
                        </ul>
                      </div>
                      <div class="order-price-group">
                        <div class="order-price-status">
                          <span
                            v-if="item.orderStatus == 1"
                            class="order-price-status-title"
                            style="color: #f5594a"
                            >Unpaid</span
                          >
                          <span
                            v-if="item.orderStatus == 2"
                            class="order-price-status-title"
                            style="color: rgb(0, 134, 246) color-blue"
                            >Paid</span
                          >
                          <span
                            v-if="item.orderStatus == 3"
                            class="order-price-status-title color-blue"
                            >Determined</span
                          >
                          <span
                            v-if="item.orderStatus == 4"
                            class="order-price-status-title color-grey"
                            >Pending refund</span
                          >
                          <span
                            v-if="item.orderStatus == 5"
                            class="order-price-status-title color-grey"
                            >Refund successful</span
                          >
                          <span
                            v-if="item.orderStatus == 6"
                            class="order-price-status-title color-grey"
                            >Unpaid canceled</span
                          >
                          <span
                            v-if="item.orderStatus == 7"
                            class="order-price-status-title color-grey"
                            style="color: rgb(0, 134, 246)"
                            >Payment failed</span
                          >
                          <span
                            v-if="item.orderStatus == 8"
                            class="order-price-status-title color-grey"
                            >Order closed</span
                          >
                          <span
                            v-if="item.orderStatus == 9"
                            class="order-price-status-title color-grey"
                            >Refund failed</span
                          >
                          <span
                            v-if="item.orderStatus == 10"
                            class="order-price-status-title color-grey"
                            style="color: #f5594a"
                            >Refunding</span
                          >
                        </div>
                        <div class="order-price-detail">
                          <div class="order-price-num">
                            <span class="order-price-num">Online payment </span
                            ><span class="order-blod-price"
                              >HKD {{ item.finalPrice }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul class="btns">
                      <li class="btn">
                        <el-button
                          type="primary"
                          plain
                          @click="goDetail(item.orderId)"
                          >Order details</el-button
                        >
                      </li>
                      <li class="btn">
                        <el-button
                          type="primary"
                          plain
                          @click="ReserveClick(item)"
                          >Booking again</el-button
                        >
                      </li>
                      <!-- <li class="btn">
                        <el-button type="primary" plain>取消</el-button>
                      </li> -->
                      <li class="btn">
                        <el-button
                          type="primary"
                          v-if="item.orderStatus == 1"
                          plain
                          @click="payFun(item)"
                          >Resubmit</el-button
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
                <div style="height: 500px; background: #fff" v-else>
                  <el-empty
                    :image-size="250"
                    description="There are currently no orders available"
                  ></el-empty>
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <Footer />
    <loading :show="loadingShow" />
  </div>
</template>

<script>
import MineTab from "@/components/MineTab/mineTab.vue";
import { hotelOrders, hotelPay } from "@/api/index";
import { redirectToCheckout, loadAirwallex } from "airwallex-payment-elements";
import { getDifferTime } from "@/utils/pub";
export default {
  components: {
    MineTab,
  },
  data() {
    return {
      editableTabsValue: '1',
      editableTabs: [
        {
          title: "All",
          name: '1',
        },
        {
          title: "Pending",
          name: "2",
        },
        {
          title: "Confirmed",
          name: "3",
        },
        {
          title: "Other",
          name: "4",
        },
      ],
      tabIndex: 2,
      orderData: [],
      loadingShow: false,
    };
  },
  created() {
    loadAirwallex({
      env: "prod",
      origin: window.location.origin,
    });
    this.getOrders();
  },
  methods: {
    rangeSeparator(time) {
      return (
        getDifferTime(time[0], time[1]) +
        (getDifferTime(time[0], time[1]) > 1
          ? " nights"
          : " night")
      );
    },
    payFun(item) {
      let data = {
        openId: "",
        orderId: item.orderId,
        payType: "h5", //写死
      };
      hotelPay(data).then((res) => {
        console.log(res.code);
        if (res.code == 200) {
            let token = sessionStorage.getItem('token').split('Bearer ')
            redirectToCheckout({
              env: "prod",
              mode: "payment",
              intent_id: res.data.h5PayParam.intent_id,
              client_secret: res.data.h5PayParam.client_secret,
              currency: res.data.h5PayParam.currency,
              methods: ["card", "wechatpay", "fps", "alipaycn"],
              theme: {
                // Must provide theme to display the checkout page properly
                fonts: [
                  // Customizes the font for the payment elements
                  {
                    src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
                    family: "AxLLCircular",
                    weight: 400,
                  },
                ],
              },
              successUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/success?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
              failUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/failure?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
              cancelUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/cancel?token=${token[1]}&orderId=${res.data.orderId}&env=web`,
            });
          }
      })
    },
    getTodayAndTomorrowDate() {
      const now = new Date();
      let todayDate = this.formatDate(now);
      let tomorrow = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24小时*60分钟*60秒*1000毫秒
      let tomorrowDate = this.formatDate(tomorrow);
      return [todayDate, tomorrowDate];
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    ReserveClick(item) {
      console.log(this.getTodayAndTomorrowDate());
      let data = {
        roomNum: item.roomNum,
        personNum: 1,
        childnNum: 0,
        hotelId: item.hotelId,
      };
      let path = `/hotel-detail?startDate=${
        this.getTodayAndTomorrowDate()[0]
      }&endDate=${this.getTodayAndTomorrowDate()[1]}&roomNum=${
        data.roomNum
      }&personNum=${data.personNum}&placeProvince=${
        data.placeProvince
      }&childnNum=${data.childnNum}&hotelId=${item.hotelId}`;
      this.$router.push(path);
    },
    tabClick(e) {
      console.log(e);
      this.getOrders();
    },
    getOrders() {
      this.loadingShow = true;
      hotelOrders({ payStatus: this.editableTabsValue }).then((res) => {
        this.loadingShow = false;
        this.orderData = res.data;
      }).catch(err => {
        this.loadingShow = false;
      })
    },
    goDetail(id) {
      sessionStorage.setItem('order',id)
      this.$router.push("/order-detail");
    },
  },
};
</script>


<style lang="scss" scoped>
.order {
  .content {
    min-height: 60vh;
    background: #f5f7fa;
    padding-bottom: 32px;

    .panel {
      width: 1160px;
      margin: 0 auto;
      padding-top: 24px;
      display: flex;

      .order-list {
        width: calc(100% - 148px);
        background: #fff;
        padding: 18px;

        .item {
          border: 1px solid #d9d9d9;
          margin-bottom: 10px;
          position: relative;

          .order-item-title {
            font-size: 16px;
            line-height: 20px;
            color: #111;
            margin-bottom: 6px;

            span {
              display: block;
              font-weight: 700 !important;
            }
          }

          .order-item-txt {
            font-size: 12px;
            line-height: 16px;
            color: #333;
            margin-bottom: 6px;
          }
        }

        h3 {
          font-family: Tahoma;
          font-weight: 400;
          font-size: 12px;
          color: #333;
          padding-left: 10px;
          padding-top: 8px;
          height: 26px;
          background-color: #f6f6f6;
        }

        .el-checkbox {
          margin-right: 8px;
        }

        .card-order-id {
          color: #06c;
          margin-right: 24px;
        }

        .order-items {
          margin: 10px 0 10px 28px;
          display: flex;
          justify-content: space-between;
        }

        .order-info {
          max-width: 720px;
          float: left;
        }

        .order-price-group {
          text-align: right;
          margin-right: 12px;
        }

        .order-price-status {
          display: inline-block;
          vertical-align: top;
          margin: 0 0 4px;

          .order-price-status-title {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
          }
        }

        .order-price-detail {
          display: block;
          vertical-align: top;

          .order-price-num {
            color: #666;
            font-size: 12px;
            line-height: 20px;
            text-align: right;
          }

          .order-blod-price {
            color: #333;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0;
          }
        }

        .btns {
          display: flex;
          justify-content: flex-end;
          margin-right: 12px;
          margin-top: 10px;
          padding-bottom: 20px;

          .btn {
            margin-left: 10px;
          }
        }
      }
    }
  }
}
</style>