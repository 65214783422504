<template>
  <div
    class="home"
    :style="recommendedShow ? 'background: #F1F6F8;' : ''"
    @click="closeSearch"
  >
    <template v-if="!recommendedShow">
      <div class="index1" style="width:100%;height:100vh;">
        <div class="home-bg">
          <img
            :key="bgIndex"
            data-aos="fade"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-easing="ease-in-sine"
            v-if="bgIndex == 0"
            src="https://hotelwebp.oss-cn-beijing.aliyuncs.com/image 9@2x.png"
            alt=""
          />
          <img
            :key="bgIndex"
            data-aos="fade"
            data-aos-duration="1500"
            data-aos-offset="0"
            data-aos-easing="ease-in-sine"
            v-else
            src="https://hotelwebp.oss-cn-beijing.aliyuncs.com/image 10@2x.png"
            alt=""
          />
        </div>
        <div class="panel">
          <div class="home-top">
            <div @click.stop>
              <logo />
            </div>
            <avatar @click.stop />
          </div>

          <div class="home-txt">
            <img src="../../assets/images/a comfortable trip@2x.png" alt="" />
          </div>

          <div class="home-search" @click.stop>
            <search-hotel :position="'top'" ref="searchHotel" />
          </div>

          <div class="home-arrow" @click.stop="getRecommended">
            <img src="../../assets/images/image 13@2x.png" alt="" />
          </div>
        </div>
      </div>
      <div class="index2" style="width:100%;height:100vh;">
        <img style="width:100%;height:100%;" src="https://hotelwebp.oss-cn-beijing.aliyuncs.com/index1.png" alt="">
      </div>
      <div class="index3" style="width:100%;height:100vh;">
        <img style="width:100%;height:100%;" src="https://hotelwebp.oss-cn-beijing.aliyuncs.com/index2.png" alt="">
      </div>
    </template>

    <template v-else>
      <div class="home-bg--2">
        <img src="https://hotelwebp.oss-cn-beijing.aliyuncs.com/第二屏背景@2x.png" alt="" />
      </div>
      <div class="panel panel2">
        <div class="home-top">
          <logo @goIndex="goIndex" @click.stop />
          <div class="searchHotel-box">
            <search-hotel ref="searchHotel" />
          </div>
          <avatar @click.stop />
        </div>

        <div class="home-recommended">
          <h3>Hotel recommendations</h3>
          <div class="recommended-list">
            <div
              class="recommended-list-item"
              v-for="(item, index) in RecommendedHotelData"
              :key="index"
              @click="goDateil(item)"
            >
              <div class="item-top">
                <img :src="item.faceImageUrl[0]" alt="" />
              </div>
              <div class="item-bottom">
                <p class="title">{{ item.enName }}({{ item.name }})</p>
                <p class="star">
                  <img
                    v-for="(subItem, subIndex) in item.hotelStar"
                    :key="subIndex"
                    src="../../assets/images/hotel_star@2x.png"
                    alt=""
                  />
                </p>
                <p class="price">
                  <span>{{ item.placeCountry }} {{ item.placeProvince }}</span>
                  <span class="price-box">
                    <span
                      class="original"
                      v-if="item.bestOriPriceDay != item.minPriceAfterTicket"
                    >
                      <span class="currency">{{ item.currency }}</span>
                      {{ item.bestOriPriceDay }}</span
                    >
                    <span class="currency">{{ item.currency }}</span>
                    {{ item.minPriceAfterTicket }}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- <Header /> -->

    <!-- <div class="content">
      <div class="top">
        <img src="../../assets/images/bg1.png" alt="" />

        <div class="top-tips">TIANTIANZHU</div>

        <div class="top-search">
          <search-hotel ref="searchHotel" />
        </div>
      </div>

      <div class="hotel-recommend">
        <h3>Hotel recommendations</h3>
        <div class="hotel-list">
          <div
            class="hotel-list-item"
            v-for="(item, index) in RecommendedHotelData"
            :key="index"
            @click="goDateil(item)"
          >
            <div class="item-top">
              <img :src="item.faceImageUrl[0]" alt="" />
            </div>
            <div class="item-bottom">
              <p class="title">
                {{ item.enName }}
                <span style="font-size: 12px">({{ item.name }})</span>
              </p>
              <p class="price">
                <span
                  class="original"
                  v-if="item.bestOriPriceDay != item.minPriceAfterTicket"
                  >{{ item.currency }}{{ item.bestOriPriceDay }}</span
                >
                {{ item.currency }}{{ item.minPriceAfterTicket }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <Footer :background="'#f8fafd'" />
    <loading :show="loadingShow" /> -->
  </div>
</template>
  
<script>
import AOS from "aos";
import "aos/dist/aos.css";
import { hotelRecommended, promotionCodeCounts } from "@/api/index";
import logo from "@/components/logo.vue";
import avatar from "@/components/avatar.vue";
export default {
  name: "Home",
  components: {
    logo,
    avatar,
  },
  data() {
    return {
      bgIndex: 0,
      recommendedShow: false,
      starData: [
        {
          name: "Two stars",
        },
        {
          name: "Three stars",
        },
        {
          name: "Four stars",
        },
        {
          name: "Five stars",
        },
      ],
      starRequest: [],
      RecommendedHotelData: [],
      loadingShow: false,
    };
  },
  mounted() {
    AOS.init(); // 一行代码搞定
    this.getHotelRecommendedHotel();
    if (this.$route.query.promotionCode) {
      promotionCodeCounts({ code: this.$route.query.promotionCode }).then(
        (res) => {
          sessionStorage.setItem(
            "promotionCode",
            this.$route.query.promotionCode
          );
        }
      );
    }
  },
  methods: {
    goIndex() {
      console.log(111);
      this.recommendedShow = !this.recommendedShow;
    },
    getRecommended() {
      this.recommendedShow = true;
    },
    goDateil(item) {
      let searchHotel = this.$refs.searchHotel.searchData;
      let data = {
        startDate: searchHotel.time[0],
        endDate: searchHotel.time[1],
        roomNum: searchHotel.roomNum,
        personNum: searchHotel.personNum,
        placeProvince: searchHotel.city,
        childnNum: searchHotel.childnNum,
        hotelId: item.id,
      };
      sessionStorage.setItem("hotelSearchData", JSON.stringify(data));
      // 1728744489818886146
      // ?startDate=${data.time[0]}&endDate=${data.time[1]}&roomNum=${data.roomNum}&personNum=${data.personNum}&placeProvince=${data.city}&childnNum=${data.childnNum}&hotelId=${item.id}`, // 这里的路径就可以正常的写，不需要添加_blank: true
      let routeUrl = this.$router.resolve({
        path: `/hotel-detail`,
      });
      window.open(routeUrl.href, "_blank");
    },
    getHotelRecommendedHotel() {
      console.log(this.$refs.searchHotel.searchData.time);
      let data = {
        startDate: this.$refs.searchHotel.searchData.time[0],
        endDate: this.$refs.searchHotel.searchData.time[1],
        rentType: 1,
        roomNum: 1,
        personNum: 2,
      };
      this.loadingShow = true;
      hotelRecommended(data)
        .then((res) => {
          this.RecommendedHotelData = res.data;
          this.RecommendedHotelData.map((v) => {
            v.hotelStar = Number(v.hotelStar);
          });
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
        });
    },
    closeSearch() {
      this.bgIndex = (this.bgIndex + 1) % 2;
      this.$refs.searchHotel.close();
      console.log();
    },
    selectStar(item) {
      let ind = this.starRequest.findIndex((v) => v == item.name);
      if (ind == -1) {
        this.starRequest.push(item.name);
      } else {
        this.starRequest.splice(ind, 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  position: relative;
  min-height: 100%;
  .home-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  .panel {
    position: relative;
    height: 100%;
    padding: 0 48px;

    .home-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 48px 0 0;
    }

    .home-txt {
      margin: 260px auto 0;
      width: 837px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }

    .home-search {
      width: 1200px;
      margin: 162px auto 0;
      //height: 102px;
    }

    .home-arrow {
      width: 50px;
      height: 64px;
      margin: 42px auto 0;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .home-bg--2 {
    position: absolute;
    width: 100%;
    height: 674px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 75% 50%;
    }
  }

  .panel2 {
    position: relative;
    width: 1440px;
    margin: 0 auto;
    padding: 0;
    padding-top: 0;

    .home-recommended {
      //width: calc(1440px - 80px);
      background: #ffffff;
      border-radius: 20px 20px 20px 20px;
      margin-top: 40px;
      padding: 28px 40px;

      h3 {
        font-weight: 600;
        font-size: 32px;
        color: #000000;
        line-height: 48px;
        margin-bottom: 24px;

        span {
          color: #b9744d;
        }
      }

      .recommended-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .recommended-list-item {
          box-shadow: 0px 4px 16px 0px rgba(17, 41, 74, 0.08);
          border-radius: 8px 8px 8px 8px;
          margin-bottom: 16px;
          .item-top {
            width: 325px;
            height: 215px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 8px 8px 0px 0px;
            }
          }

          .item-bottom {
            width: calc(325px - 32px);
            padding: 16px 16px 20px;

            .title {
              font-weight: 600;
              font-size: 16px;
              color: #0a0d1a;
              line-height: 24px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            .star {
              margin: 10px 0 8px;
              height: 12px;
              img {
                width: 12px;
                height: 12px;
                margin-right: 4px;
              }
            }

            .price {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              font-weight: 400;
              font-size: 12px;
              color: #0a0d1a;
              line-height: 20px;

              .price-box {
                font-weight: 400;
                font-size: 18px;
                color: #0c50ad;
                line-height: 28px;
              }

              .currency {
                font-weight: 400;
                font-size: 12px;
                color: #0c50ad;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    ::v-deep {
      /* 小于1435 px  */
      @media screen and (min-width: 1279px) and (max-width: 1435px) {
        .li-item-calenda {
          width: 290px !important;
        }

        .li-item-guests {
          padding-left: 28px !important;
          padding-right: 28px !important;
        }
      }

      @media screen and (min-width: 1200px) and (max-width: 1280px) {
        .li-item-calenda {
          width: 290px !important;
        }

        .li-item-guests {
          padding-left: 28px !important;
          padding-right: 28px !important;
        }
      }
    }
  }

  /* 小于1435 px  */
  @media screen and (min-width: 1279px) and (max-width: 1440px) {
    .home-txt {
      margin: 30px auto 0 !important;
    }

    .home-search {
      margin: 105px auto 0 !important;
    }

    .home-arrow {
      margin: 42px auto 0;
    }

    .searchHotel-box {
      width: 980px;
    }

    .panel2 {
      width: 1200px;
    }

    .home-recommended {
      width: auto;
    }
  }

  @media screen and (min-width: 1000px) and (max-width: 1280px) {
    .home-txt {
      margin: 30px auto 0 !important;
    }

    .home-search {
      margin: 105px auto 0 !important;
      width: 1100px !important;
    }

    .home-arrow {
      margin: 42px auto 0;
    }

    .searchHotel-box {
      width: 980px;
    }

    .panel2 {
      width: 1200px !important;
    }

    .home-recommended {
      width: auto;
    }
  }

  /* 大于 1680px  */
  @media screen and (min-width: 1681px) {
    .box {
      width: 1500px;
      height: 200px;
      background-color: blue;
    }
  }

  .content {
    padding-bottom: 72px;
    min-height: 60vh;
    .top {
      position: relative;
      width: 100%;
      //overflow-x: hidden;
      img {
        position: absolute;
        left: -42px;
        width: 106%;
        height: 280px;
        -o-object-fit: cover;
        object-fit: cover;
        -o-object-position: 50% 50%;
        object-position: 50% 50%;
        border-radius: 0 0 26% 26%;
      }

      > div {
        position: relative;
      }

      .top-tips {
        width: 1200px;
        margin: 0 auto;
        color: #fff;
        font-size: 38px;
        font-weight: bold;
        padding: 70px 0px 0;
      }

      .top-search {
        //position: relative;
        //top: 38px;
        margin: 88px auto;
        width: calc(1160px - 48px);
        //height: 176px;
        background: #fff;
        box-shadow: 0 8px 20px 0 rgba(97, 121, 157, 0.16);
        padding: 24px;

        .top-star {
          margin-top: 20px;
          .label {
            color: #333;
            font-size: 16px;
            margin-right: 12px;
          }

          .rate {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            background: #f0f2f5;
            border-radius: 1px;
            height: 22px;
            margin-right: 16px;
            padding: 4px 8px;
            font-size: 12px;
            color: #0f294d;
            cursor: pointer;
            border: 1px solid transparent;
          }

          .reta-ative {
            background: #0f294d;
            color: #fff;
          }
        }
      }
    }

    .hotel-recommend {
      width: 1160px;
      margin: 0 auto;
      h3 {
        font-size: 28px;
        color: #333;
        font-weight: bold;
        text-align: center;
        margin-bottom: 32px;
        margin-top: 70px;
      }

      .hotel-list {
        display: flex;
        justify-content: space-between;
        > div {
          cursor: pointer;
        }
        .item-top {
          width: 278px;
          height: 197px;
          margin-bottom: 16px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .item-bottom {
          .title {
            width: 261px;
            font-size: 16px;
            color: #333;
            line-height: 1.3;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-weight: 600;
          }

          .price {
            margin-left: 4px;
            font-size: 18px;
            font-weight: 700;
            color: #0f46a3;
            margin-top: 8px;
          }
        }
      }
    }
  }
}

.original {
  color: #455873;
  text-decoration: line-through;
  margin-top: 10px;
  font-size: 10px !important;
  margin-right: 8px;

  span {
    font-size: 10px !important;
    color: #455873 !important;
  }
}
</style>
  