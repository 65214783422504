import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/utils/rem'
// element-ui引入
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/element-variables.scss'
import locale from 'element-ui/lib/locale/lang/en'

Vue.use(ElementUI, { locale })

// 百度地图
import BauduMap from 'vue-baidu-map'
Vue.use(BauduMap, {
  ak: '3g0Bgr0LqGcwaoDpvKldgynriCtR4MlM'
})


// import VueAnalytics from "vue-analytics";
// Vue.use(VueAnalytics, {
//   id: "G-3XPSPFPVE1",
//   router,
//   autoTracking: {
//     page: true,
//     screenview: true
//   }
// });

// 公共css
import '@/assets/css/reset.css'
import '@/assets/css/elementUI.scss'
import '@/assets/fonts/iconfont/iconfont.css'
import '@/assets/css/var.scss'


// 公共组件
import Header from '@/components/Header/index.vue'
Vue.component('Header', Header)

import Footer from '@/components/Footer/index.vue'
Vue.component('Footer', Footer)

import SearchHotel from '@/components/SearchHotel/index.vue'
Vue.component('search-hotel', SearchHotel)

import Loading from '@/components/Loading/index.vue'
Vue.component('loading', Loading)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
