<template>
  <div class="reserve">
    <Header />
    <div class="content">
      <div class="g-container p-book">
        <div class="p-book_wrapper">
          <div class="bookMain bookMain-v2">
            <div class="hotelAndRoomInfo">
              <div class="title">
                <div class="hotelName">
                  {{ hotelData.enName }}
                  <span class="f14">({{ hotelData.name }})</span>
                </div>
                <div class="star">
                  <el-rate v-model="hotelData.hotelStar" disabled> </el-rate>
                </div>
              </div>
              <div class="address">
                <div class="icon">
                  <i class="el-icon-location"></i>
                </div>
                <div class="text">
                  {{ hotelData.placeCountry }}
                  <span v-if="hotelData.placeProvince">，</span>
                  {{ hotelData.placeProvince
                  }}<span v-if="hotelData.placeCity">，</span
                  >{{ hotelData.placeCity
                  }}<span v-if="hotelData.placeInfoEn">，</span
                  >{{ hotelData.placeInfoEn }}
                </div>
              </div>
              <div class="roomName">
                {{ roomData.nameEn }}
                <span class="f12">({{ roomData.nameCn }})</span>
              </div>
              <div class="facility">
                <div class="item">
                  <div class="icon">
                    <i class="el-icon-user"></i>
                  </div>
                  <div class="text">
                    <span class=""
                      >{{ roomData.allowPersonNum }} people check-in</span
                    >
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <i class="icon iconfont icon-chuang"></i>
                  </div>
                  <div class="text">
                    <span class="hasDetail">{{ roomData.bedTypeName }}</span
                    ><i
                      type="ic_new_question"
                      class="u-icon u-icon-ic_new_question questionIcon"
                    ></i>
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <i class="icon iconfont icon-zaocan"></i>
                  </div>
                  <div class="text">
                    <span class="">{{
                      breakfastType(roomData.breakfastType)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="divide"></div>
              <div class="bookNotice" data-send="devTrace">
                <div class="title">
                  <div class="text">Required Reading for Booking</div>
                </div>
                <div class="item">
                  <div class="icon">
                    <i
                      type="ic_new_warning_line"
                      class="el-icon-warning-outline"
                    ></i>
                  </div>
                  <div class="text">Check in at the store</div>
                </div>
                <div class="item">
                  <div class="icon">
                    <i
                      type="ic_new_warning_line"
                      class="el-icon-warning-outline"
                    ></i>
                  </div>
                  <div class="text">
                    On the day of check-in, the hotel requires you to provide
                    additional identification for check-in
                  </div>
                </div>
                <div class="item">
                  <div class="icon">
                    <i
                      type="ic_new_warning_line"
                      class="el-icon-warning-outline"
                    ></i>
                  </div>
                  <div class="text">
                    Please note your arrival and check-out times
                  </div>
                </div>
              </div>
              <div class="divide"></div>
              <ul class="courage">
                <li class="item">
                  The number of properties is tight, please place an order
                  quickly!
                </li>
                <li class="item">
                  No need to wait! This order can be confirmed immediately
                </li>
              </ul>
            </div>
            <div class="m-guestInfo">
              <div class="dateAndRoom">
                <div class="m-dateInfo">
                  <div class="m-checkInOut">
                    <div class="m-checkInOut_time">
                      <div class="timeLable">Check-in</div>
                      <div class="time">{{ query.startDate }}</div>
                    </div>
                    <div class="m-checkInOut_qty">
                      {{ rangeSeparator([query.startDate, query.endDate]) }}
                    </div>
                    <div class="m-checkInOut_time checkOut">
                      <div class="timeLable">Check-out</div>
                      <div class="time">{{ query.endDate }}</div>
                    </div>
                  </div>
                </div>
                <div class="m-rooms-v2">
                  <div class="m-rooms-v2_title">Number of rooms</div>
                  <div class="m-rooms-v2_warn">
                    <!-- 剩{{ roomData.lastRoomNum }}间 -->
                    <el-input-number
                      v-model="query.roomNum"
                      :min="1"
                      :max="maxRoomNum"
                      size="mini"
                    ></el-input-number>
                  </div>
                </div>
              </div>
              <div class="m-guestInfo_title">Resident information</div>
              <div class="m-guestInfo_remark">
                Please fill in according to the actual number of guests, and
                ensure that the name matches the identification document<i
                  class="el-icon-warning-outline"
                ></i>
              </div>
              <div
                class="m-guestInfo-box"
                v-for="(item, index) in roomData.checkMemberArr"
                :key="index"
              >
                <div class="guestContainer-input">
                  <div class="m-inputBox">
                    <span class="m-inputBox_label">Last name</span
                    ><span class="inputWrapper">
                      <el-input
                        v-model="item.firstName"
                        placeholder="Example：HAN"
                      ></el-input>
                    </span>
                    <span class="tips">{{ item.firstNameErrorMsg }}</span>
                  </div>
                  <div class="m-inputBox">
                    <span class="m-inputBox_label">First name</span
                    ><span class="inputWrapper">
                      <el-input
                        v-model="item.lastName"
                        placeholder="Example：LEI"
                      ></el-input
                    ></span>
                    <span class="tips">{{ item.lastNameErrorMsg }}</span>
                  </div>
                </div>
                <!-- 邮箱 -->
                <div class="m-concatInfo_email">
                  <div class="m-inputBox">
                    <span class="m-inputBox_label">Email</span
                    ><span class="inputWrapper">
                      <el-input
                        v-model="item.email"
                        placeholder="Email"
                      ></el-input>
                    </span>
                    <span class="tips">{{ item.emailErrorMsg }}</span>
                  </div>
                </div>
                <!-- 手机号 -->
                <div class="m-concatInfo_phone">
                  <span class="phoneLabel">Phone number</span>
                  <div id="ibu_phone_selector">
                    <div id="region-selector-component" style="width: 348px">
                      <div class="l-content u-clearfix withCommonStyle">
                        <div class="country-select">
                          <div class="selected-box">
                            <el-select
                              v-model="item.telFlag"
                              placeholder="Please select"
                            >
                              <el-option
                                v-for="item in telFlagOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              >
                              </el-option>
                            </el-select>
                          </div>
                        </div>
                        <el-input
                          class="number-input zh-cn"
                          v-model="item.phone"
                          placeholder="Phone Number"
                        ></el-input>
                      </div>
                    </div>
                    <span class="tips">{{ item.phoneErrorMsg }}</span>
                  </div>
                </div>

                <div class="guestContainer-input children-box">
                  <div class="m-inputBox">
                    <span class="m-inputBox_label"
                      >Children (age)<el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addChildren(index, '儿童')"
                      ></el-button></span
                    ><span class="inputWrapper childrenInput">
                      <div
                        class="childrenInput-box"
                        :key="childIndex"
                        v-for="(childItem, childIndex) in item.childAgeList"
                      >
                        <el-input
                          v-model="childItem.age"
                          placeholder="Example：10"
                        ></el-input>
                        <span class="tips">{{ childItem.numErrorMsg }}</span>
                      </div>
                    </span>
                  </div>
                </div>

                <div class="guestContainer-input children-box">
                  <div class="m-inputBox">
                    <span class="m-inputBox_label"
                      >Infant (age)<el-button
                        size="mini"
                        type="primary"
                        icon="el-icon-plus"
                        @click="addChildren(index, '婴儿')"
                      ></el-button></span
                    ><span class="inputWrapper childrenInput">
                      <div
                        v-for="(childItem, childIndex) in item.babyAgeList"
                        :key="childIndex"
                      >
                        <el-input
                          v-model="childItem.age"
                          placeholder="Example：10"
                        ></el-input>
                        <span class="tips">{{ childItem.numErrorMsg }}</span>
                      </div>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="m-specialReuest">
              <div class="m-title">
                <div class="main-title">
                  <h3>Requirement</h3>
                  <span>(Optional)</span>
                </div>
                <div class="sub-title">
                  We will convey your needs to the hotel, but we cannot
                  guarantee that they will be met.
                </div>
              </div>
              <ul class="m-content">
                <li>
                  <div class="m-input">
                    <el-input
                      type="textarea"
                      :rows="6"
                      placeholder="If there are any other requirements, please fill them out and contact the hotel. The confirmation time may be longer, please be patient."
                      v-model="remark"
                    >
                    </el-input>
                  </div>
                </li>
              </ul>
            </div>

            <div class="m-payment">
              <div class="discount-box">
                <!-- 原价 -->
                <div
                  class="discount-item"
                  v-if="spend.originalDiscountAmount"
                  @click="selDiscountPrice(0)"
                >
                  <div
                    class="select-item"
                    :class="{ 'sel-active': selectCurrent == 0 }"
                  ></div>
                  <div class="info-item">
                    <div class="info-item-top">
                      <span class="original-tag">original price</span>
                      <span
                        v-if="spend.originalDiscountAmount.refundAmount != 0"
                        >Cancel refund before check-in：HKD
                        {{ spend.originalDiscountAmount.refundAmount }}
                      </span>
                      <span
                        v-else-if="
                          spend.originalDiscountAmount.refundAmount == 0
                        "
                        >Booking non refundable</span
                      >
                    </div>
                    <div class="info-item-bottom">
                      <span class=""
                        >HKD
                        {{
                          spend.originalDiscountAmount.currentPriceTotal
                        }}</span
                      >
                      <span class="through"
                        >HKD
                        {{
                          spend.originalDiscountAmount.originalPriceTotal
                        }}</span
                      >
                    </div>
                  </div>
                </div>
                <!-- 早鸟 -->
                <div
                  class="discount-item"
                  @click="selDiscountPrice(1)"
                  v-if="spend.earlyDiscountAmount"
                >
                  <div
                    class="select-item"
                    :class="{ 'sel-active': selectCurrent == 1 }"
                  ></div>
                  <div class="info-item">
                    <div class="info-item-top">
                      <span class="discount-tag"
                        >{{
                          spend.earlyDiscountAmount.discountPercentage
                        }}折</span
                      >
                      <span v-if="spend.earlyDiscountAmount.refundAmount != 0"
                        >Cancel refund before check-in：HKD
                        {{ spend.earlyDiscountAmount.refundAmount }}
                      </span>
                      <span
                        v-else-if="spend.earlyDiscountAmount.refundAmount == 0"
                        >Booking non refundable</span
                      >
                    </div>
                    <div class="info-item-bottom">
                      <span class=""
                        >HKD
                        {{ spend.earlyDiscountAmount.currentPriceTotal }}</span
                      >
                      <span class="through f24 pl-16"
                        >HKD
                        {{ spend.earlyDiscountAmount.originalPriceTotal }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- 连住 -->
                <div
                  class="discount-item"
                  @click="selDiscountPrice(2)"
                  v-if="spend.continuousDiscountAmount"
                >
                  <div
                    class="select-item"
                    :class="{ 'sel-active': selectCurrent == 2 }"
                  ></div>
                  <div class="info-item">
                    <div class="info-item-top">
                      <span class="discount-tag"
                        >{{
                          spend.continuousDiscountAmount.discountPercentage
                        }}折</span
                      >
                      <span
                        v-if="spend.continuousDiscountAmount.refundAmount != 0"
                        >Cancel refund before check-in：HKD
                        {{ spend.continuousDiscountAmount.refundAmount }}
                      </span>
                      <span
                        v-else-if="
                          spend.continuousDiscountAmount.refundAmount == 0
                        "
                        >Booking non refundable</span
                      >
                    </div>
                    <div class="info-item-bottom">
                      <span class=""
                        >HKD
                        {{
                          spend.continuousDiscountAmount.currentPriceTotal
                        }}</span
                      >
                      <span class="through f24 pl-16"
                        >HKD
                        {{
                          spend.continuousDiscountAmount.originalPriceTotal
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- 三日租 -->
                <div
                  class="discount-item"
                  @click="selDiscountPrice(3)"
                  v-if="spend.threeDaysDiscountAmount"
                >
                  <div
                    class="select-item"
                    :class="{ 'sel-active': selectCurrent == 3 }"
                  ></div>
                  <div class="info-item">
                    <div class="info-item-top">
                      <span class="discount-tag"
                        >{{
                          spend.threeDaysDiscountAmount.discountPercentage
                        }}折</span
                      >
                      <span
                        v-if="spend.threeDaysDiscountAmount.refundAmount != 0"
                        >Cancel refund before check-in：HKD
                        {{ spend.threeDaysDiscountAmount.refundAmount }}
                      </span>
                      <span
                        v-else-if="
                          spend.threeDaysDiscountAmount.refundAmount == 0
                        "
                        >Booking non refundable</span
                      >
                    </div>
                    <div class="info-item-bottom">
                      <span class=""
                        >HKD
                        {{
                          spend.threeDaysDiscountAmount.currentPriceTotal
                        }}</span
                      >
                      <span class="through f24 pl-16"
                        >HKD
                        {{
                          spend.threeDaysDiscountAmount.originalPriceTotal
                        }}</span
                      >
                    </div>
                  </div>
                </div>

                <!-- 月租 -->
                <div
                  class="discount-item"
                  @click="selDiscountPrice(4)"
                  v-if="spend.monthlyDiscountAmount"
                >
                  <div
                    class="select-item"
                    :class="{ 'sel-active': selectCurrent == 4 }"
                  ></div>
                  <div class="info-item">
                    <div class="info-item-top">
                      <span class="discount-tag"
                        >{{
                          spend.monthlyDiscountAmount.discountPercentage
                        }}折</span
                      >
                      <span v-if="spend.monthlyDiscountAmount.refundAmount != 0"
                        >Cancel refund before check-in：HKD
                        {{ spend.monthlyDiscountAmount.refundAmount }}
                      </span>
                      <span
                        v-else-if="
                          spend.monthlyDiscountAmount.refundAmount == 0
                        "
                        >Booking non refundable</span
                      >
                    </div>
                    <div class="info-item-bottom">
                      <span class=""
                        >HKD
                        {{
                          spend.monthlyDiscountAmount.currentPriceTotal
                        }}</span
                      >
                      <span class="through f24 pl-16"
                        >HKD
                        {{
                          spend.monthlyDiscountAmount.originalPriceTotal
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="total-pay">
                <div class="m-payment_content">
                  <div class="price0 payText">
                    <span class="warn"
                      >Online payment
                      <span
                        label="span"
                        class="description-link description-link-0"
                        >HKD {{ currentPriceTotal }}</span
                      ></span
                    >&nbsp;
                  </div>
                </div>
                <div class="m-payment_button">
                  <el-button type="warning" @click="submitCheck"
                    >Go pay</el-button
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="bookSide bookSideNewEdition">
            <div class="side-part">
              <div class="price-list">
                <div class="price-list-title">Cost details</div>
                <div class="price-list-content">
                  <div class="price-info-title">
                    <span>Online payment</span>
                    <span class="text-right"
                      >HKD {{ currentDiscountAmount.currentPriceTotal }}</span
                    >
                  </div>
                  <div
                    class="po-relative"
                    v-for="(
                      item, index
                    ) in currentDiscountAmount.discountAmountDays"
                    :key="index"
                  >
                    <div class="price-info-price">
                      <span>{{ item.date }}</span>
                      <span>HKD {{ item.currentPrice }}</span>
                    </div>
                  </div>
                </div>
                <!-- 优惠券 -->
                <div class="ticket-box" v-if="ticket.discountAmount">
                  <div class="ticket-info-title">
                    <span>Discount</span>
                    <el-popover
                      placement="left"
                      width="350"
                      trigger="click"
                      popper-class="custom-popover"
                    >
                      <div class="ticket-list">
                        <div
                          class="ticket-list-item"
                          v-for="(item, index) in ticketEnableDTOS"
                          :key="index"
                        >
                          <div class="ticket-item-left">
                            <div class="">
                              <span class="tag">{{
                                getTicketType(item.ticketType)
                              }}</span>
                              <span class="ticketName">
                                {{ item.ticketName }}</span
                              >
                            </div>
                            <div class="scopeName">
                              {{ item.scopeName }},Each person is limited to
                              {{ item.userTopTicket }} cards per session
                            </div>

                            <div class="time">
                              Valid until：{{ item.effectDate }}-{{
                                item.expireDate
                              }}
                            </div>
                          </div>

                          <div class="ticket-item-right">
                            <div
                              class="coupon-right--text"
                              v-if="
                                item.ticketType == 1 ||
                                item.ticketType == 2 ||
                                item.ticketType == 4
                              "
                            >
                              -{{ item.preferential
                              }}<span class="coupon-right--text-unit">{{
                                item.ticketType == 4 ? " days" : " yuan"
                              }}</span>
                            </div>
                            <div
                              class="coupon-right--text"
                              v-if="item.ticketType == 3"
                            >
                              -{{ item.preferential
                              }}<span class="coupon-right--text-unit">%</span>
                            </div>
                            <div
                              class="color-fff f24 text-c"
                              v-if="item.ticketType == 1"
                            >
                              Over {{ item.conditions }} yuan
                            </div>
                            <div
                              class="color-fff f24 text-c"
                              v-if="item.ticketType == 4"
                            >
                              Over{{ item.conditions }} days
                            </div>
                            <div
                              class="coupon-right--btn"
                              @click="checkFun(item)"
                            >
                              {{ item.check ? "Selected" : "select" }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <span
                        class="text-right"
                        style="cursor: pointer"
                        slot="reference"
                      >
                        {{
                          ticket.discountAmount < 0
                            ? ticket.discountAmount
                            : "-" + ticket.discountAmount
                        }}</span
                      >
                    </el-popover>
                  </div>
                </div>
                <div class="total-info">
                  <span>Total payable amount</span>
                  <div class="total-price-type">
                    <span></span>
                    <span>HKD{{ currentPriceTotal }}</span>
                  </div>
                </div>
                <div class="m-policies">
                  <div class="title-encourage">Non cancellable</div>
                  <p>
                    The order cannot be cancelled after successful booking. If
                    you have not checked in, you will be charged a portion of
                    the fee (if using a coupon, the price paid after the coupon
                    will prevail). The order needs to be confirmed by the hotel
                    or supplier before it takes effect. The confirmation result
                    of the order is subject to email notification. If the order
                    is not confirmed, a full refund will be made to your payment
                    account.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <loading :show="loadingShow" />
  </div>
</template>

<script>
import { hotelHotelDetail, hotelPreOrder, hotelOrderAdd } from "@/api/index";
// from the library
import { redirectToCheckout, loadAirwallex } from "airwallex-payment-elements";
import { getDifferTime } from "@/utils/pub";
export default {
  data() {
    return {
      maxRoomNum:2,
      popperOptions: {
        boundariesElement: "#app",
        gpuAcceleration: false,
      },
      selectCurrent: 0,
      input: "",
      textarea: "",
      hotelData: {},
      roomData: {},
      query: {},
      spend: {},
      currentDiscountAmount: {},
      loadingShow: false,
      remark: "",
      telFlagOptions: [
        {
          label: "China",
          value: "+86",
        },
        {
          label: "Hong Kong",
          value: "+852",
        },
        {
          label: "Macao",
          value: "+853",
        },
      ],
      ticket: {},
      ticketEnableDTOS: [],
      currentPriceTotal: 0,
      currentPriceTotalCopy: 0,
      discountAmount: 0,
    };
  },
  created() {
    loadAirwallex({
      env: "prod",
      origin: window.location.origin,
    });
    this.query = JSON.parse(sessionStorage.getItem("hotelSearchData"));
    this.getDetail();
    this.getOrderInfo();
  },
  watch: {
    "query.roomNum": {
      deep: true,
      handler(newval, oldval) {
        if (oldval != undefined) {
          let arr = JSON.parse(localStorage.getItem("checkHotel"));
          arr.checkMemberArr = [];
          for (let i = 0; i < newval; i++) {
            arr.checkMemberArr.push({
              telFlag: "+86",
              firstName: "", // 入住姓
              lastName: "", // 入住名
              phone: "", //手机号
              email: "", //邮箱
              firstNameErrorMsg: "",
              emailErrorMsg: "",
              lastNameErrorMsg: "",
              phoneErrorMsg: "",
              childNum: 0,
              childAgeList: [], //儿童
              babyNum: 0,
              babyAgeList: [], //婴儿
              roomId: arr.id,
              roomType: arr.nameCn,
              roomOrderPrice:
                arr.ratePlanVOS[this.query.ratePlanIndex].currentPrice,
              roomPayPrice:
                arr.ratePlanVOS[this.query.ratePlanIndex].originalPrice,
              roomLessPrice: 130.099,
              clockSelect: arr.clockSelect,
            });
          }
          this.roomData = arr;
          this.getOrderInfo();
        }
      },
    },
  },
  methods: {
    rangeSeparator(time) {
      if (time.length != 0) {
        return (
          getDifferTime(time[0], time[1]) +
          (getDifferTime(time[0], time[1]) > 1 ? " nights" : " night")
        );
      }
    },
    checkFun(item) {
      if (item.enable) {
        this.currentPriceTotal =
          Math.round(
            (this.currentPriceTotalCopy - Number(item.discountAmount)) * 100
          ) / 100;
        if (this.currentPriceTotal < 0) {
          this.currentPriceTotal = 0.01;
        }
        this.ticket = {
          ...item,
        };

        this.ticketEnableDTOS.map((v) => {
          v.check = false;
          if (v.id === item.id && !item.check) {
            v.check = true;
          }
        });
      }
    },
    getTicketType(type) {
      if (type == 1) {
        return "满减券";
      } else if (type == 2) {
        return "直减券";
      } else if (type == 3) {
        return "折扣券";
      } else if (type == 4) {
        return "赠送券";
      }
    },
    countDTOS(val) {
      if (this.spend[val] && this.spend[val].length > 0) {
        this.spend[val].map((item) => {
          if (item.enable) {
            this.ticketEnableDTOS.push({
              ...item.ticket,
              check: item.needChoose ? item.needChoose : false,
              discountAmount: item.discountAmount,
              enable: item.enable,
            });

            if (item.needChoose) {
              this.ticket = {
                ...item.ticket,
                check: item.needChoose ? item.needChoose : false,
                discountAmount: item.discountAmount,
                enable: item.enable,
              };

              this.currentPriceTotal =
                this.currentDiscountAmount.currentPriceTotal -
                item.discountAmount;
            }
          }
        });
      }
    },
    selDiscountPrice(val) {
      this.selectCurrent = val;
      this.ticket = {};
      this.ticketEnableDTOS = [];
      switch (val) {
        case 0:
          this.currentDiscountAmount = this.spend.originalDiscountAmount;
          this.currentPriceTotal =
            this.spend.originalDiscountAmount.currentPriceTotal;
          this.currentPriceTotalCopy =
            this.spend.originalDiscountAmount.currentPriceTotal;
          // discountAmount = this.spend.originalDiscountAmount.discountTotal
          //   ? this.spend.originalDiscountAmount.discountTotal
          //   : 0;
          this.countDTOS("originalTicketEnableDTOS");
          break;

        case 1:
          this.currentDiscountAmount = this.spend.earlyDiscountAmount;
          this.currentPriceTotal =
            this.spend.earlyDiscountAmount.currentPriceTotal;
          this.currentPriceTotalCopy =
            this.spend.earlyDiscountAmount.currentPriceTotal;
          // discountAmount = this.spend.earlyDiscountAmount.discountTotal
          //   ? this.spend.earlyDiscountAmount.discountTotal
          //   : 0;
          this.countDTOS("earlyTicketEnableDTOS");
          break;

        case 2:
          this.currentDiscountAmount = this.spend.continuousDiscountAmount;
          this.currentPriceTotal =
            this.spend.continuousDiscountAmount.currentPriceTotal;
          this.currentPriceTotalCopy =
            this.spend.continuousDiscountAmount.currentPriceTotal;
          // discountAmount = this.spend.continuousDiscountAmount.discountTotal
          //   ? this.spend.continuousDiscountAmount.discountTotal
          //   : 0;
          this.countDTOS("continuousTicketEnableDTOS");
          break;

        case 3:
          this.currentDiscountAmount = this.spend.threeDaysDiscountAmount;
          this.currentPriceTotal =
            this.spend.threeDaysDiscountAmount.currentPriceTotal;
          this.currentPriceTotalCopy =
            this.spend.threeDaysDiscountAmount.currentPriceTotal;
          // discountAmount = this.spend.threeDaysDiscountAmount.discountTotal
          //   ? this.spend.threeDaysDiscountAmount.discountTotal
          //   : 0;
          this.countDTOS("threeDaysTicketEnableDTOS");
          break;

        case 4:
          this.currentDiscountAmount = this.spend.monthlyDiscountAmount;
          this.currentPriceTotal =
            this.spend.monthlyDiscountAmount.currentPriceTotal;
          this.currentPriceTotalCopy =
            this.spend.monthlyDiscountAmount.currentPriceTotal;
          // discountAmount = this.spend.monthlyDiscountAmount.discountTotal
          //   ? this.spend.monthlyDiscountAmount.discountTotal
          //   : 0;
          this.countDTOS("monthlyTicketEnableDTOS");
          break;
      }
    },
    addChildren(index, val) {
      let arrName = val == "儿童" ? "childAgeList" : "babyAgeList";
      let arrNum = val == "儿童" ? "childNum" : "babyNum";
      let item = this.roomData.checkMemberArr[index];
      item[arrName].push({ age: "" });
    },
    breakfastType(type) {
      // 1含双早 2含单早 3不含早餐
      let res = "";
      if (type === 1) {
        res = "with breakfast";
      } else if (type === 0) {
        res = "Excluding breakfast";
      }
      return res;
    },
    getDetail() {
      hotelHotelDetail({
        id: JSON.parse(sessionStorage.getItem("hotelSearchData")).hotelId,
      }).then((res) => {
        if (res.code == 200) {
          this.hotelData = res.data;
          this.hotelData.hotelStar = Number(this.hotelData.hotelStar);
        }
      })
    },
    getOrderInfo() {
      this.loadingShow = true;
      let arr = JSON.parse(localStorage.getItem("checkHotel"));
      this.maxRoomNum = arr.lastRoomNum
      arr.checkMemberArr = [];
      for (let i = 0; i < this.query.roomNum; i++) {
        arr.checkMemberArr.push({
          telFlag: "+86",
          firstName: "", // 入住姓
          lastName: "", // 入住名
          phone: "", //手机号
          email: "", //邮箱
          firstNameErrorMsg: "",
          emailErrorMsg: "",
          lastNameErrorMsg: "",
          phoneErrorMsg: "",
          childNum: 0,
          childAgeList: [], //儿童
          babyNum: 0,
          babyAgeList: [], //婴儿
          roomId: arr.id,
          roomType: arr.nameCn,
          roomOrderPrice:
            arr.ratePlanVOS[this.query.ratePlanIndex].currentPrice,
          roomPayPrice: arr.ratePlanVOS[this.query.ratePlanIndex].originalPrice,
          roomLessPrice: 130.099,
          clockSelect: arr.clockSelect,
        });
      }
      this.roomData = arr;
      let roomId = [];
      for (let i = 0; i < this.query.roomNum; i++) {
        roomId.push(
          JSON.parse(sessionStorage.getItem("hotelSearchData")).roomId
        );
      }
      let data = {
        ...this.query,
        breakfastType: this.roomData.breakfastType,
        clockParams: [],
        refundable: this.roomData.refundable,
        rentType: 1,
        roomId: roomId,
      };
      hotelPreOrder(data).then((res) => {
        this.loadingShow = false;
        this.spend = res.data;
        this.currentDiscountAmount = this.spend.originalDiscountAmount;
        this.currentPriceTotalCopy =
          this.spend.originalDiscountAmount.currentPriceTotal;
        this.discountAmount = res.data.originalDiscountAmount.discountTotal
          ? res.data.originalDiscountAmount.discountTotal
          : 0;
        this.currentPriceTotal =
          res.data.originalDiscountAmount.currentPriceTotal;
        this.ticketEnableDTOS = [];
        if (
          res.data.originalTicketEnableDTOS &&
          res.data.originalTicketEnableDTOS.length > 0
        ) {
          res.data.originalTicketEnableDTOS.map((item) => {
            if (item.enable) {
              this.ticketEnableDTOS.push({
                ...item.ticket,
                check: item.needChoose ? item.needChoose : false,
                discountAmount: item.discountAmount,
                enable: item.enable,
              });

              if (item.needChoose) {
                this.ticket = {
                  ...item.ticket,
                  check: item.needChoose ? item.needChoose : false,
                  discountAmount: item.discountAmount,
                  enable: item.enable,
                };

                this.currentPriceTotal = (
                  res.data.originalDiscountAmount.currentPriceTotal -
                  item.discountAmount
                ).toFixed(2);
              }
            }
          });
        }
      }).catch(err => {
        this.loadingShow = false;
      })
    },
    submitCheck() {
      let flag = false;
      let addData = [];
      var item;
      var numReg = /^\d+$/;
      var telRule =
        /^((\+|00)86)?1[3-9]\d{9}$|^852\d{8}$|^853\d{8}$|^(886|0)9\d{8}$/;
      var emailReg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if (this.roomData.checkMemberArr) {
        for (let j = 0; j < this.roomData.checkMemberArr.length; j++) {
          item = this.roomData.checkMemberArr[j];

          if (item.firstName == "") {
            this.roomData.checkMemberArr[j].firstNameErrorMsg =
              "Please enter the reserved last name";
            flag = true;
          } else {
            this.roomData.checkMemberArr[j].firstNameErrorMsg = "";
          }

          if (item.lastName == "") {
            this.roomData.checkMemberArr[j].lastNameErrorMsg =
              "Please enter the reservation name";
            flag = true;
          } else {
            this.roomData.checkMemberArr[j].lastNameErrorMsg = "";
          }

          if (item.phone == "") {
            this.roomData.checkMemberArr[j].phoneErrorMsg =
              "Please enter your phone number";
            flag = true;
          } else {
            if (item.telFlag == "+86" && !telRule.test(item.phone)) {
              this.roomData.checkMemberArr[j].phoneErrorMsg =
                "Please enter the correct phone number";
              flag = true;
            } else {
              this.roomData.checkMemberArr[j].phoneErrorMsg = "";
            }
          }

          if (item.email == "") {
            this.roomData.checkMemberArr[j].emailErrorMsg =
              "Please enter your email address";
            flag = true;
          } else {
            if (!emailReg.test(item.email)) {
              this.roomData.checkMemberArr[j].emailErrorMsg =
                "Please enter the correct email address";
              flag = true;
            } else {
              this.roomData.checkMemberArr[j].emailErrorMsg = "";
            }
          }
          let newChildAgeList = JSON.parse(JSON.stringify(item.childAgeList));
          if (newChildAgeList.length != 0) {
            newChildAgeList.map((v, k) => {
              if (v.age == "") {
                newChildAgeList[k].numErrorMsg = "Please enter your age";
                flag = true;
              } else if (!numReg.test(v.age)) {
                newChildAgeList[k].numErrorMsg = "Must be a positive integer";
                flag = true;
              } else {
                newChildAgeList[k].numErrorMsg = "";
              }
            });
          }
          let newBabyAgeList = JSON.parse(JSON.stringify(item.babyAgeList));
          if (newBabyAgeList.length != 0) {
            newBabyAgeList.map((v, k) => {
              if (v.age == "") {
                newBabyAgeList[k].numErrorMsg = "Please enter your age";
                flag = true;
              } else if (!numReg.test(v.age)) {
                newBabyAgeList[k].numErrorMsg = "Must be a positive integer";
                flag = true;
              } else {
                newBabyAgeList[k].numErrorMsg = "";
              }
            });
          }
          this.roomData.checkMemberArr[j].babyAgeList = newBabyAgeList;
          this.roomData.checkMemberArr[j].childAgeList = newChildAgeList;
          // let obj = {
          //   firstName: item.firstName,
          //   lastName: item.lastName,
          //   phone: item.telFlag + item.phone,
          //   email: item.email,
          //   childAgeList: [],
          //   babyAgeList: [],
          //   roomType: item.roomType,
          //   roomOrderPrice: item.roomOrderPrice,
          //   roomPayPrice: item.roomPayPrice,
          //   roomLessPrice: item.roomLessPrice,
          //   roomTypeId: item.roomId,
          // };
          // item.childAgeList.map((v) => obj.childAgeList.push(v.age));
          // item.babyAgeList.map((v) => obj.babyAgeList.push(v.age));
          // addData.push(obj);
        }
      }
      if (flag) {
        return;
      } else {
        this.submit();
      }
    },
    submit() {
      let orderInfo = [];
      this.loadingShow = true;
      this.roomData.checkMemberArr.map((item) => {
        let obj = {
          firstName: item.firstName,
          lastName: item.lastName,
          phone: item.telFlag + item.phone,
          email: item.email,
          childAgeList: [],
          babyAgeList: [],
          roomType: item.roomType,
          roomOrderPrice: item.roomOrderPrice,
          roomPayPrice: item.roomPayPrice,
          roomLessPrice: item.roomLessPrice,
          roomTypeId: item.roomId,
        };
        item.childAgeList.map((v) => obj.childAgeList.push(v.age));
        item.babyAgeList.map((v) => obj.babyAgeList.push(v.age));
        orderInfo.push(obj);
      });
      let data = {
        breakfastType: this.roomData.breakfastType,
        refundable: this.roomData.refundable,
        checkDate: this.query.startDate,
        outDate: this.query.endDate,
        dicountTicketId: this.ticket.id,
        discountAmount: this.ticket.discountAmount,
        discountTypeCode: "",
        finalPrice: this.currentPriceTotal,
        originalPrice: this.currentDiscountAmount.originalPriceTotal,
        hotelId: this.query.hotelId,
        orderInfo: orderInfo,
        payType: "h5",
        remark: this.remark,
        rentType: 1,
        roomDiscounts: this.currentDiscountAmount.roomDiscounts,
        roomNum: this.query.roomNum,
        serverType: 1,
        status: 1,
        stayAudltNum: this.query.personNum,
        stayChildNum: this.query.childnNum,
        stayNum: 1,
        type: 1,
      };
      hotelOrderAdd(data).then((res) => {
        this.loadingShow = false;
        if (res.code == 200) {
          let token = sessionStorage.getItem("token").split("Bearer ");
          redirectToCheckout({
            env: "prod",
            mode: "payment",
            intent_id: res.data.h5PayParam.intent_id,
            client_secret: res.data.h5PayParam.client_secret,
            currency: res.data.h5PayParam.currency,
            methods: ["card", "wechatpay", "fps", "alipaycn"],
            theme: {
              // Must provide theme to display the checkout page properly
              fonts: [
                // Customizes the font for the payment elements
                {
                  src: "https://checkout.airwallex.com/fonts/CircularXXWeb/CircularXXWeb-Regular.woff2",
                  family: "AxLLCircular",
                  weight: 400,
                },
              ],
            },
            successUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/success?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
            failUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/failure?token=${token[1]}&orderId=${res.data.orderId}&env=web`, // Must be HTTPS sites
            cancelUrl: `https://hotelback.luckyrili.com/hk/payment/h5/callback/cancel?token=${token[1]}&orderId=${res.data.orderId}&env=web`,
          });
        }else{
          this.$message.error(res.msg)
        }
      }).catch(err => {
        this.loadingShow = false;
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.reserve {
  .content {
    min-height: 90vh;
    background: #f5f7fa;
    padding-bottom: 32px;

    .g-container {
      min-width: 768px;
      margin: 0 auto;
      padding: 16px 32px;
      color: #0f294d;
      max-width: 1160px !important;

      .p-book_wrapper {
        display: flex;
        justify-content: space-between;
        .bookMain {
          width: calc(100% - 376px - 16px - 48px);
          background: #fff;
          padding: 24px;
          border: 2px;

          .hotelAndRoomInfo {
            border-bottom: 4px solid #f5f7fa;
            padding-bottom: 16px;
            margin-bottom: 16px;
            .title {
              display: flex;
              align-items: center;
              .hotelName {
                display: inline;
                color: #0f294d;
                font-size: 24px;
                font-weight: 700;
                letter-spacing: 0;
                margin-right: 13px;
                line-height: 1.4;
              }
            }

            .address {
              display: flex;
              align-items: center;
              margin-top: 8px;

              .text {
                color: #0f294d;
                font-size: 14px;
                font-weight: 400;
                letter-spacing: 0;
                margin-left: 8px;
              }
            }

            .roomName {
              color: #0f294d;
              font-size: 16px;
              font-weight: 700;
              letter-spacing: 0;
              margin-top: 16px;
            }

            .facility {
              display: flex;
              align-items: center;
              margin-top: 8px;
            }

            .item {
              display: flex;
              align-items: center;
              margin-top: 8px;
              .icon {
                font-size: 16px;
                margin-right: 8px;
              }

              .text {
                color: #0f294d;
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 0;
                margin-right: 16px;
                display: flex;
                align-items: center;
              }
            }

            .divide {
              border-top: 1px dashed #dadfe6;
              margin-top: 16px;
            }

            .bookNotice {
              display: flex;
              flex-direction: column;
              margin-top: 16px;

              .title {
                margin-top: 8px;
                .text {
                  color: #0f294d;
                  font-size: 16px;
                  font-weight: 700;
                  letter-spacing: 0;
                  margin-right: auto;
                }
              }

              .item {
              }
            }

            .courage {
              margin-top: 18px;
              padding-left: 24px;

              .item {
                position: relative;
                margin-top: 10px;
                list-style: disc;
                color: #ff6f00;
                font-size: 16px;
                font-weight: 700;
                letter-spacing: 0;

                &::before {
                  position: absolute;
                  left: -16px;
                  content: "";
                  width: 6px;
                  height: 6px;
                  border-radius: 50%;
                  background: #ff6f00;
                }
              }
            }
          }

          .m-guestInfo {
            border-bottom: 4px solid #f5f7fa;
            padding-bottom: 22px;
            margin-bottom: 24px;
            .dateAndRoom {
              display: flex;
              justify-content: space-between;
              border-bottom: 1px dashed #dadfe6;
              margin-bottom: 16px;

              .m-checkInOut {
                border: none !important;
                padding: 0 0 16px !important;
                display: flex;
                line-height: 18px;
              }

              .m-checkInOut_time {
                padding-bottom: 10px;
                border-bottom: 1px solid #ced2d9;

                .timeLable {
                  color: #455873 !important;
                  font-size: 14px !important;
                  font-weight: 400;
                  letter-spacing: 0;
                }

                .time {
                  font-size: 14px;
                  margin-top: 8px;
                  color: #0f294d;
                }
              }

              .m-checkInOut_qty {
                align-self: center;
                color: #0f294d !important;
                flex: none;
                width: 80px;
                order: 0 !important;
                padding: 0 !important;
                margin: 0 20px;
                text-align: center !important;
                font-size: 14px;
              }

              .m-rooms-v2 {
                line-height: 1;
                min-width: 174px;

                .m-rooms-v2_title {
                  color: #455873;
                  font-size: 14px;
                  font-weight: 400;
                  letter-spacing: 0;
                }

                .m-rooms-v2_warn {
                  color: #f5594a;
                  font-size: 14px;
                  font-weight: 700;
                  letter-spacing: 0;
                  margin-top: 14px;
                }
              }
            }

            .m-guestInfo-box {
              margin-top: 24px;
            }

            .m-guestInfo_title {
              font-size: 20px;
              line-height: 26px;
            }

            .m-guestInfo_remark {
              color: #8592a6;
              font-size: 14px;
              line-height: 18px;
              padding-top: 3px;
            }

            .guestContainer-input {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              flex-wrap: wrap;

              .el-button {
                margin-left: 12px;
              }
            }

            .childrenInput {
              //display: flex !important;
              flex-wrap: wrap;
              justify-content: space-between;

              .el-input {
                width: 48%;
                margin-bottom: 6px;
              }
            }

            .children-box {
              margin-top: 18px;

              .m-inputBox {
                width: 100% !important;
              }
            }

            .m-guestInfo_remark {
              display: flex;
              justify-content: space-between;

              .m-inputBox {
                flex: 1;
              }

              .m-inputBox_label {
                color: #455873;
                font-size: 14px;
                line-height: 18px;
              }

              .m-inputBox .inputWrapper {
                position: relative;
                display: inline-block;
                width: 100%;
              }
            }

            .m-concatInfo_email,
            .m-concatInfo_phone {
              margin-top: 24px;
              display: block;
            }

            .m-inputBox {
              width: calc(50% - 12px) !important;

              .m-inputBox_label {
                color: #455873;
                font-size: 14px;
                line-height: 18px;
              }

              .inputWrapper {
                position: relative;
                display: inline-block;
                width: 100%;
              }

              ::v-deep {
                .el-input__inner {
                  background: #fff;
                  width: 100%;
                  height: 40px;
                  box-sizing: border-box;
                  font-size: 16px;
                  font-weight: 700;
                  border: solid #ced2d9;
                  border-width: 0 0 1px;
                  transition: border-color 0.25s;
                  color: #0f294d;
                  padding: 0;
                  border-radius: 0;
                }
              }
              input {
                background: #fff;
                width: 100%;
                height: 40px;
                box-sizing: border-box;
                font-size: 16px;
                font-weight: 700;
                border: solid #ced2d9;
                border-width: 0 0 1px;
                transition: border-color 0.25s;
                color: #0f294d;

                &:focus-visible {
                  border: none;
                }
              }
            }

            .m-concatInfo_phone {
              .phoneLabel {
                color: #455873;
                font-size: 14px;
                line-height: 18px;
              }

              ::v-deep {
                .el-input--suffix .el-input__inner {
                  border-top: none;
                  border-left: none;
                  border-right: none;
                  border-radius: 0;
                  height: 38px;
                }

                .el-input__inner {
                  margin-bottom: 0 !important;
                  padding-left: 0;
                }

                .el-select > .el-input {
                  margin-bottom: 0 !important;
                }
              }

              #region-selector-component {
                width: calc(50% - 12px) !important;
              }

              #region-selector-component > .l-content {
                width: 100%;
                background: #fff;
                height: 40px;
                -webkit-box-sizing: border-box;
                box-sizing: border-box;
                position: relative;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
              }

              #ibu_hotel_container
                #region-selector-component
                > .l-content.withCommonStyle {
                border: none;
              }

              #region-selector-component
                > .l-content.withCommonStyle
                .country-select {
                border-bottom: 1px solid #ced2d9;
                margin-right: 8px;
                min-width: 80px;
              }

              #region-selector-component
                > .l-content.withCommonStyle
                .country-select
                .selected-box
                span {
                color: #0f294d;
                font-weight: 700;
                margin-right: 12px;
              }

              #region-selector-component
                > .l-content.withCommonStyle
                .number-input {
                height: 39px;
                border-bottom: 1px solid #ced2d9;
                margin: 0;
                font-size: 16px;
                color: #0f294d;
                font-weight: 700;

                ::v-deep {
                  .el-input__inner {
                    border: none;
                    border-bottom: 1px solid #ced2d9;
                    border-radius: 0;
                  }
                }
              }

              #region-selector-component
                > .l-content.withCommonStyle
                .country-select
                .selected-box {
                border: none;
                font-size: 16px;
                padding: 0;
                width: 100%;
                height: 38px;
                line-height: 40px;
                margin: 0;
              }
            }
          }

          .m-specialReuest {
            border-bottom: 4px solid #f5f7fa;
            padding-bottom: 22px;
            margin-bottom: 24px;
            .m-title {
              color: #0f294d;

              .main-title {
                h3 {
                  font-size: 20px;
                  line-height: 26px;
                  display: inline-block;
                  font-weight: 700;
                }

                span {
                  color: #8592a6;
                  margin-left: 8px;
                  font-size: 14px;
                }
              }

              .sub-title {
                margin-top: 8px;
                color: #8592a6;
                font-size: 14px;
              }
            }

            .m-content {
              margin-top: 20px;
              font-size: 14px;

              ::v-deep {
                .el-textarea__inner {
                  padding: 6px 12px;
                }
              }
            }
          }

          .m-payment {
            margin-bottom: 8px;
            background: #fff;

            .total-pay {
              display: flex;
              align-items: center;
              justify-content: space-between;
            }

            .m-payment_content {
              color: #0f294d;
              font-size: 14px;

              .description-link {
                color: #0065f2;
                font-size: 24px;
                font-weight: 700;
              }
            }

            .m-payment_button {
              .el-button {
                width: 110px;
                height: 60px;
                font-size: 18px;
              }
            }
          }
        }
        .bookSide {
          .side-part {
            width: 328px;
            min-height: 400px;
            background: #fff;
            padding: 24px;
            border: 2px;
            .price-list {
              .price-list-title {
                font-size: 16px;
                font-weight: 600;
                color: #0f294d;
              }

              .price-list-content {
                padding-top: 24px;
                padding-bottom: 24px;
                .price-info-title {
                  display: flex;
                  justify-content: space-between;
                  font-size: 14px;
                  font-weight: 600;
                  color: #0f294d;
                }

                .text-right {
                  color: #287dfa;
                }

                .po-relative {
                  .price-info-price {
                    display: flex;
                    justify-content: space-between;
                    font-size: 14px;
                    color: #455873;
                    padding-top: 8px;
                    margin-top: 8px;

                    &:last-child {
                      // color: ;
                    }
                  }
                }
              }

              .total-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-top: 24px;
                border-top: 1px dashed rgba(0, 0, 0, 0.1);
                border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                padding-bottom: 16px;

                > span {
                  font-size: 14px;
                  font-weight: 600;
                  margin-right: 24px;
                  flex-shrink: 0;
                  color: #0f294d;
                }

                .total-price-type {
                  span {
                    &:first-child {
                      font-size: 14px;
                      font-weight: 600;
                      color: #0f294d;
                    }

                    &:last-child {
                      font-weight: 600;
                      font-size: 24px;
                      margin-left: 4px;
                      color: #287dfa;
                    }
                  }
                }
              }

              .m-policies {
                margin-top: 16px;
                // border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
                padding-bottom: 16px;

                .title-encourage {
                  color: #06aebd;
                  line-height: 18px;
                  font-weight: 700;
                  font-size: 14px;
                }

                p {
                  color: #8592a6;
                  line-height: 21px;
                  margin-top: 8px;
                  font-size: 14px;
                }
              }
            }

            .ticket-box {
              margin-bottom: 12px;
              .ticket-info-title {
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                color: #455873;
              }
            }
          }
        }
      }
    }

    .discount-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .discount-item {
        width: 46%;
        height: 90px;
        border: 1px solid #ccc;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        padding: 10px 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .select-item {
          width: 18px;
          height: 18px;
          border: 1px solid #000;
          border-radius: 50%;
          margin-right: 12px;
        }

        .sel-active {
          position: relative;

          &::before {
            position: absolute;
            content: "";
            width: 12px;
            height: 12px;
            background: #000;
            border-radius: 50%;
            left: 3px;
            top: 3px;
          }
        }

        .info-item-top {
          margin-bottom: 22px;
        }

        .info-item {
          width: 85%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          &-top {
            .discount-tag {
              color: #ffffff;
              background: #e34444;
              border-radius: 4px;
              padding: 6px;
              margin-right: 12px;
              font-size: 13px;
              display: inline-block;
            }

            .original-tag {
              color: #ffffff;
              background: #0f46a3;
              border-radius: 4px;
              padding: 6px;
              margin-right: 12px;
              font-size: 13px;
              display: inline-block;
            }
          }

          &-bottom {
            .through {
              text-decoration: line-through;
              color: #999cac;
              margin-left: 12px;
            }
          }
        }
      }
    }

    .tips {
      display: block;
      height: 20px;
      font-size: 12px;
      color: red;
      line-height: 20px;
    }
  }
}
</style>